import {
  AnnotationFilters,
  AssignFilterValue,
  FilterData,
  PagesFilterValue,
  ShapesFilterValue,
  OriginFilterValue,
} from 'common/components/drawings/interfaces/drawing-filters';
import { RequestStatus } from 'common/enums/request-status';
import { DrawingsDrawMode } from '../enums';
import { AutoMeasurePageSetting } from '../enums/auto-measure-page-setting';
import { WizzardStatus } from '../enums/dropper-state';
import {
  AiRequestQuality,
  DrawingsCalibrationState,
  DrawingsSelectAggregationGroup,
  LineQuality,
  MagicSearchState,
  TextSearchPagesType,
} from '../interfaces';
import {
  AutoMeasureSettingsState,
  DrawingsState,
  DrawingsUserAnnotationsState,
  ExportSettings,
} from '../interfaces/drawings-state';
import {
  AnnotationOptions,
  MeasurementOptions,
  PageOptions,
} from '../layout-components/top-control-group/drawings-export/drawings-export-options';
import { DrawingMarkShapes, DrawingStrokeStyles } from './drawing-styles';

export const FilterDataInitialState: FilterData = {
  [AnnotationFilters.Shapes]: {
    [ShapesFilterValue.Points]: true,
    [ShapesFilterValue.Polygons]: true,
    [ShapesFilterValue.Lines]: true,
  },
  [AnnotationFilters.Pages]: PagesFilterValue.Current,
  [AnnotationFilters.Report]: null,
  [AnnotationFilters.Color]: null,
  [AnnotationFilters.Name]: '',
  [AnnotationFilters.Assign]: AssignFilterValue.All,
  [AnnotationFilters.Users]: null,
  [AnnotationFilters.Origin]: OriginFilterValue.All,
};

export const AutoMeasureSettingsInitialState: AutoMeasureSettingsState = {
  availableOptions: [],
  selectedOptions: [],
  pageSetting: AutoMeasurePageSetting.Current,
};

export const UserAnnotationsInitialState: DrawingsUserAnnotationsState = {
  stickers: {},
  images: {},
  selectedAnnotations: [],
  annotationLoadingStatus: RequestStatus.NotRequested,
  legend: null,
  rulers: {},
  currentDrawingId: null,
  groupsMeasuresCache: {},
  tempSticker: null,
};

export const InitialFileSystemHighlightState = {
  filesWithSelectedElement: {},
  pagesWithSelectedElement: {},
};

export const InitialTextSearchSettings = {
  query: undefined,
  caseSensitive: false,
  wholeWord: false,
  regExp: false,
  pages: TextSearchPagesType.All,
};

export const InitialSearchResults = {
  count: 0,
  settings: InitialTextSearchSettings,
  results: {},
};

export const InitialExportSettings: ExportSettings = {
  drawingStylesEnabled: false,
  exportPages: PageOptions.All,
  exportMeasurements: MeasurementOptions.All,
  exportAnnotations: [AnnotationOptions.Stickers, AnnotationOptions.Rulers],
  segmentLength: false,
  segmentLengthOnOtherLayer: false,
  loadingExport: false,
};


export const CallibrationInitialState: DrawingsCalibrationState = {
  lineLength: null,
  lineLengthPx: null,
  metersPerPx: null,
  paperSize: null,
  drawingLength: null,
  originalLength: null,
};

export const MagicSearchInitialState: MagicSearchState = {
  contoursToSearch: [],
  contours: [],
  previews: [],
  previewsToDelete: [],
  contoursToDelete: [],
  lastSnapped: [],
  replacedContours: [],
  fixedContours: [],
  fixContour: null,
  resultToSources: {},
  status: WizzardStatus.Start,
  dpi: 300,
  searchArea: null,
  searchAreaSpecifyMode: false,
  similarity: .5,
  query: '',
  snappingStatus: RequestStatus.NotRequested,
  hiddenPreviews: [],
};

export const DrawingsInitialState: DrawingsState = {
  drawingInstances: {},
  currentDrawingInfo: null,
  drawingsInfo: {},
  selectedInstances: [],
  hiddenInstances: [],
  pinnedGroupIds: [],
  drawingSnappingGeometry: {},
  snappingGeometry: null,
  files: { entities: {}, rootIds: [], filesCount: 0 },
  filesCount: 0,
  tagDrawings: {},
  loadStatus: RequestStatus.NotRequested,
  isInstancesBoolOperationsEnabled: false,
  fsHighlightStatus: {
    filesWithSelectedElement: {},
    pagesWithSelectedElement: {},
  },
  drawingPaperPoints: {},
  aiAnnotation: {
    pointsInfo: {},
    points: {},
    geometry: {},
    fileData: {},
    additionalColors: {},
    geometryCreators: [],
  },
  drawingGeometryGroups: [],
  drawingGeometryOpenGroups: {},
  selectGeometryGroup: [],
  elementMeasurement: {},
  selectedFile: null,
  selectedPages: [],
  filteredElementIds: null,
  filterData: FilterDataInitialState,
  drawingGroupTemplates: [],
  userAnnotations: UserAnnotationsInitialState,
  usedColors: [],
  groupsLoaded: false,
  textSearch: {
    settings: InitialTextSearchSettings,
    isLoading: false,
    isError: false,
    results: InitialSearchResults,
    textRectangles: { drawingId: null, rects: [] },
    isTextLoading: false,
    isActive: false,
  },
  autoMeasureSettings: AutoMeasureSettingsInitialState,
  exportSettings: InitialExportSettings,
  contextMenuPosition: null,
  hoverState: null,
  showOnlyGroups: false,
  currentDrawingSnapping: null,
  showOptimizeMessage: false,
  filteredItems: [],
  drawingRenderedStatus: false,
  drawMode: DrawingsDrawMode.Disabled,
  wizzardTools: {
    connect: false,
    enclose: false,
    dropperInstanceType: DrawingsSelectAggregationGroup.Area,
    wizzardState: { status: WizzardStatus.Start, foundedItemsCount: null },
    dropperSameGeometry: false,
    searchArea: null,
    allowFlipping: false,
    allowRotation: true,
    finderSelectionArea: null,
    finderSelectedGeometries: null,
    findText: false,
    selectedToRemove: [],
    result: null,
    isCount: true,
    error: null,
    finderSelectedVisibility: { area: true, line: true },
    dropperSimilarity: 0,
    finderSimilarity: .8,
    boostWithAi: true,
    finderResultToHide: {},
    quality: AiRequestQuality.Auto,
    lineQuality: LineQuality.MediumLight,
  },
  cursorHintType: null,
  drawingStatistic: {},
  temporaryCalibration: CallibrationInitialState,
  newInstanceSettings: {
    color: null,
    name: '',
    strokeStyle: DrawingStrokeStyles.Normal,
    shape: DrawingMarkShapes.Circle,
    offset: null,
    shouldSearchSimilar: false,
  },
  is3d: false,
  magicSearch: MagicSearchInitialState,
  finishApi: null,
  pageFilterApplyStatus: {},
  instanceToOperateWith: [],
};
