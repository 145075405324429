import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import { diff } from 'deep-object-diff';
import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import {
  CANVAS_CONTAINER_MIN_WIDTH,
  DrawingsActions,
  DrawingsGeometryGroup,
  DrawingsInstanceMeasure,
  DrawingsLayout,
  WithDrawingsLayoutApi,
  withDrawingsLayoutApiContext,
} from 'common/components/drawings';
import { DrawingsUpdateActions } from 'common/components/drawings/actions/creators/update';
import { DrawingsActionTypes } from 'common/components/drawings/actions/types/common';
import { MoveToCellOptionType } from 'common/components/drawings/drawings-canvas-menus';
import {
  DrawingsFile,
  DrawingsFiles,
  DrawingsFolderViewInfo,
} from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { DrawingsGroupUtils, ParentToChildMap } from 'common/components/drawings/utils/drawings-group-utils';
import { UpdateCellData, UpdateCellDataTransaction } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { GlobalKeyboardEventsController } from 'common/components/global-keyboard-events-controller';
import { PAGE_HEADER_HEIGHT } from 'common/components/page-header';
import { ProgressActions, ProgressBarType } from 'common/components/progress';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { CLOSE_DIALOG, OPEN_DIALOG } from 'common/UIKit/dialogs/action-types';
import { arrayUtils } from 'common/utils/array-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { ConnectionStatus, multiViewActions } from 'common/utils/multi-view/multi-view-reducers';
import { MultiViewersStates } from 'common/utils/multi-view/multi-view-states';
import { ProgressUtils } from 'common/utils/progress-utils';
import { PersistedStorageActionTypes } from 'persisted-storage/actions/types';
import { PersistedStorageSelectors } from 'persisted-storage/selectors/projectTwoDFullScreenSelector';
import {
  WithCommentsApiContext,
  withCommentsApiContext,
} from 'unit-2d-comments/comments-context/with-comments-api-context';
import { CommentaryTarget } from 'unit-2d-comments/interfaces';
import { TwoDCommentsActions } from 'unit-2d-comments/store-slice';
import { CommentaryTargetTypeGuards } from 'unit-2d-comments/utils';
import { Property } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { TwoDFastNavigationActions } from 'unit-2d-database/store-slice-fast-navigation';
import { TwoDNotificationsActions } from 'unit-2d-notification/store-slice';
import { TwoDReportTemplateActions } from 'unit-2d-report-template/store-slice';
import { AnalyticsProps, withAnalyticsContext } from 'utils/posthog';
import { CommonActionTypes } from '../../actions/common/action-types';
import { SpliterPaneConfig } from '../../components/splitter-layout';
import { PlanProjectRouteParams } from '../../routes/plan';
import { PersistedStorageActions } from '../persisted-storage/actions/creators';
import { TwoDFullScreenMode } from '../persisted-storage/interfaces/state';
import { TwoDActions } from './actions/creators';
import { SheetUpdateCells } from './actions/payloads';
import { TwoDActionTypes } from './actions/types';
import { TwoDReportPanel } from './components';
import { TwoDElementViewActions, TwoDElementViewState } from './components/2d-element-view/store-slice';
import { ItemsViewTransactionContext } from './components/2d-element-view/utils';
import { Transaction } from './components/2d-element-view/utils/transaction';
import { AssignInfo, DrawingInfo } from './components/2d-element-view/utils/transaction/interfaces';
import { PageLayout } from './components/page-layout';
import { TABS_HEIGHT } from './components/styled';
import { TRACE_LINK_HEIGHT } from './components/trace-link';
import { Arrangement } from './constants';
import { isItemViewVisible } from './helpers';
import {
  CellToUpdatePayload,
  FocusedCell,
  AssignedPia,
  PiaCalculated,
  ReportPage,
  ReportTableApi,
  TablePreset,
  TableSettings,
} from './interfaces';
import { UpdateSendAssignPatchType } from './sagas';
import { TwoDFullCellId, TwoDRegexGetter } from './units/2d-regex';
import { extendWithCellStore } from './units/cell-data-controllers/exted-with-cell-store';
import { ReportCellController, ReportCellControllerProps } from './units/cell-data-controllers/report-cell-controller';
import { CellDataStore, SheetDataStore } from './units/cell-data-controllers/report-cell-data-store';
import { DrawingPayload } from './units/cell-data-controllers/report-cell-value-getter';
import { getMeasure, getMeasureKey, getSegmentMeasure } from './units/get-measure';
import { getRefMaps, UpdatedCell } from './units/get-ref-map';
import { MoveCellHelper } from './units/move-cell-helper';


interface OwnProps extends RouteComponentProps<PlanProjectRouteParams> { }

interface DispatchProps {
  loadDrawings: () => void;
  setCellToUpdate: (value: CellToUpdatePayload[]) => void;
  forceSaveAndDropDrawingState: () => void;
  setWrite: (value: boolean) => void;
  showTraceLink: (instanceId: string) => void;
  dropReportState: () => void;
  set2dFullScreenMode: (mode: TwoDFullScreenMode, projectId: string) => void;
  setTableViewType: (tableViewType: Arrangement) => void;
  updateCellToCell: (payload: Record<string, string[]>) => void;
  updateDrawingsToCell: (value: Record<string, string[]>) => void;
  updateDynamicGroupsToCell: (value: Record<string, string[]>) => void;
  setShowSheetsIds: (reportPagesIds: string[]) => void;
  setSelectedSheetId: (selectedSheetId: string) => void;
  setPages: (reportPages: ReportPage[]) => void;
  addNewSheet: (reportPage: ReportPage) => void;
  saveSplitterSize: (size: number) => void;
  setCalculatedProperties: (update: Record<string, PiaCalculated>) => void;
  fetchAssignPia: (fetchDataBase: boolean) => void;
  removeAssign: (instanceIds: string[]) => void;
  setReportEtag: (pageId: string, etag: number) => void;
  loadCommentsData: () => void;
  onSelectDrawing: (drawingId: string) => void;
  setIsGetPageData: (isGetPageData: boolean) => void;
  clearReportTempalte: () => void;
  setSkipCalcPia: () => void;
  startCalcCells: (total: number, title: string) => void;
  updateCalcCellProgress: (finished: number, title: string) => void;
  closeProgressbar: (title: string) => void;
  setTwoDElementViewHandles: (payload: TwoDElementViewState) => void;
  setMultiViewStatus: (status: ConnectionStatus) => void;
}

interface StateProps {
  focusedCell: FocusedCell;
  drawingsToCell: Record<string, string[]>;
  dynamicGroupsToCell: Record<string, string[]>;
  drawingsMeasure: Record<string, DrawingsInstanceMeasure>;
  drawingGroups: DrawingsGeometryGroup[];
  traceLink: string;
  isFullScreenDrawings: boolean;
  isFullScreenTable: boolean;
  isAutoMoveToCell: boolean;
  selectedInstances: string[];
  selectedGroups: string[];
  currentDrawing: DrawingsShortInfo;
  drawings: Record<string, DrawingsShortInfo>;
  aiAnnotation: DrawingsGeometryState;
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  isImperial: boolean;
  entities: Record<string, DrawingsFile | DrawingsFolderViewInfo>;
  cellToCells: Record<string, string[]>;
  reportPages: ReportPage[];
  selectedSheetId: string;
  defaultTableSize: number;
  assignedPia: Record<string, AssignedPia>;
  calculatedPia: Record<string, PiaCalculated>;
  originProperties: Property[];
  shouldSkipCalcPia: boolean;
  files: DrawingsFiles;
  isMultiViewConnected: boolean;
  multiViewStatus: ConnectionStatus;
  withUnit: boolean;
  showCode: boolean;
}

interface PageProps extends
  OwnProps,
  DispatchProps,
  StateProps,
  AbilityAwareProps,
  DispatchProp<AnyAction>,
  AnalyticsProps,
  WithCommentsApiContext,
  WithDrawingsLayoutApi { }

interface OwnState {
  isInsertCellValue: boolean;
  paneConfigs: SpliterPaneConfig[];
  isPageDataLoaded: boolean;
  loadedReportPages: string[];
}

export const ReportPaneSize = 'ReportPaneSize';

const whiteApiList = [
  'insertDrawingInstanceToCell',
  'updateCellsByMeasure',
  'updateRowData',
  'changeTableTab',
  'onDrawingsLoaded',
  'insertDrawingInstanceToTable',
  'insertDrawingInstanceToColumn',
  'setFocusedCell',
];

const minPaneSize = 250;
const defaultTablePaneSizeHorizontal = CANVAS_CONTAINER_MIN_WIDTH - PAGE_HEADER_HEIGHT;

const getDefaultPaneConfig = (
  tableWidth: number,
): SpliterPaneConfig[] => {
  const indent = PAGE_HEADER_HEIGHT;
  const windowSize = window.innerHeight - indent;
  const maxtableSizeHeight = CANVAS_CONTAINER_MIN_WIDTH + indent;
  let initialSize = windowSize - tableWidth;
  let tableSize = tableWidth;

  if (tableWidth > maxtableSizeHeight) {
    initialSize = windowSize - defaultTablePaneSizeHorizontal;
    tableSize = defaultTablePaneSizeHorizontal;
  }

  const pageSize = initialSize;

  const sizes = [
    { size: pageSize, minSize: minPaneSize },
    { size: tableSize, minSize: minPaneSize },
  ];
  return sizes;
};

const getFullScreenConfig = (): SpliterPaneConfig[] => {
  const indent = PAGE_HEADER_HEIGHT;
  const tabsHeight = TABS_HEIGHT;
  const pageSize = window.innerHeight - (indent + tabsHeight);
  const sizes = [
    {
      size: pageSize,
      minSize: minPaneSize,
    },
    {
      size: tabsHeight,
      minSize: tabsHeight,
      maxSize: tabsHeight,
    },
  ];
  return sizes;
};


const reduxActionBlackList = [
  TwoDActionTypes.PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE,
  CommonActionTypes.FILE_PROGRESS,
  CommonActionTypes.UPLOAD_FILE_SUCCESSED,
  CommonActionTypes.UPLOAD_FILES_ADD,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  PersistedStorageActionTypes.SET_2D_FULLSCREEN_MODE,
  TwoDFastNavigationActions.changeActiveNavigationType.type,
  UpdateSendAssignPatchType,
  DrawingsActionTypes.SET_FINISH_GEOMETRY_API,
  DrawingsActionTypes.SET_CONTEXT_MENU_POSITION,
  DrawingsActionTypes.REMOVE_DRAWING_HOVER_STATE,
  DrawingsActionTypes.SET_DRAWING_HOVER_STATE,
  DrawingsActionTypes.SET_DRAW_MODE,
  TwoDActionTypes.SEND_ASSIGN_PATCH,
  TwoDElementViewActions.setCallbacks.type,
  TwoDNotificationsActions.realtimeConnected.type,
];
const reduxActionWhiteList = [
  PersistedStorageActionTypes.TOGGLE_AUTO_MOVE_TO_CELL,
  PersistedStorageActionTypes.SET_THEME_TONE,
  PersistedStorageActionTypes.SET_THEME,
  TwoDActionTypes.SET_CALCULATED_PIA,
];

class TwoDPageComponent extends React.PureComponent<PageProps, OwnState> {
  private reportPanelApi: ReportTableApi = null;
  private originReportPanelApi: ReportTableApi = null;
  private drawingUpdatedExecutor: DeferredExecutor = new DeferredExecutor(100);
  private updateGeometryDiff: Record<string, any> = {};
  private prevGroupMap: Record<string, DrawingsGeometryGroup> = {};
  private prevGroup: DrawingsGeometryGroup[];
  private multiViewHelper = MultiViewersStates.createMultiViewState(
    'TwoDPageChannel',
    undefined,
    undefined,
    true,
    reduxActionBlackList,
    reduxActionWhiteList,
    ConstantFunctions.doNothing,
    ConstantFunctions.doNothing,
  );

  private reportCellControllerProps: ReportCellControllerProps = {
    getDrawingValue: this.getDrawingPayload,
    isImperial: this.isImperial,
    getDrawingsToCells: this.getDrawingsToCells,
    functionToRefresh: this.refreshTable,
    getCellToCells: this.getCellToCells,
    onSheetRowsDataUpdated: this.onSheetRowsDataUpdated,
    onSheetColumnsDataUpdated: this.onSheetColumnsDataUpdated,
    onReportSheetLoaded: this.onReportSheetLoaded,
    getCellsWithDrawings: this.getDrawingsInstancesInCell,
    getDynamicTable: this.getDynamicTable,
    onReportDataLoaded: this.onReportDataLoaded,
    setIsGetPageData: this.setIsGetPageData,
    startProgress: this.startCalcCells,
    updateProgress: this.updateCalcCellProgress,
    closeProgress: this.closeProgressbar,
  };
  private reportCellStoreController: ReportCellController = null;
  private reportCellStoreControllerOrigin: ReportCellController = null;
  private viewTransaction: Transaction = new Transaction(this.getDrawingInfo, this.getAssignInfo);

  constructor(props: PageProps) {
    super(props);

    this.state = {
      isInsertCellValue: false,
      paneConfigs: this.getPaneConfig(),
      isPageDataLoaded: false,
      loadedReportPages: [],
    };
    this.setReportCellController();
  }

  public static getDerivedStateFromProps(props: PageProps, prevState: OwnState): Partial<OwnState> {
    const { reportPages } = props;
    if (reportPages.length < prevState.loadedReportPages.length) {
      return {
        loadedReportPages: prevState.loadedReportPages.filter(id => reportPages.find(page => page.id === id)),
      };
    }
    return null;
  }

  public componentDidMount(): void {
    const { loadDrawings, commentsApi, fetchAssignPia, ability, loadCommentsData } = this.props;
    loadDrawings();
    commentsApi.setOnFocusToComment(this.onFocusToComment);
    if (ability.can(Operation.Read, Subject.Takeoff2dPiaAssignment)) {
      fetchAssignPia(ability.cannot(Operation.Read, Subject.PiaDatabase));
    }
    if (ability.can(Operation.Manage, Subject.Comment2d)) {
      loadCommentsData();
    }

    this.props.setTwoDElementViewHandles(this.viewTransaction.getEventHandlers());
  }

  public componentWillUnmount(): void {
    this.props.forceSaveAndDropDrawingState();
    this.props.dropReportState();
    this.props.clearReportTempalte();
    this.drawingUpdatedExecutor.reset();
    this.reportCellStoreController.dropState();
    this.multiViewHelper.destroy();
    this.props.setMultiViewStatus(null);
  }

  public componentDidUpdate(prevProps: PageProps): void {
    if (this.props.isFullScreenTable !== prevProps.isFullScreenTable
      || this.props.isFullScreenDrawings !== prevProps.isFullScreenDrawings
    ) {
      this.setState({ paneConfigs: this.getPaneConfig() });
    }

    if (this.isDrawingPayloadUpdated(prevProps)) {
      const groupDiff = this.props.drawingGroups !== prevProps.drawingGroups
        ? diff(this.getMap(prevProps.drawingGroups), this.getMap(this.props.drawingGroups))
        : {};
      const elementMeasureDiff: any = diff(prevProps.elementMeasurement, this.props.elementMeasurement);
      const pointsDiff: any = diff(this.props.aiAnnotation.pointsInfo, prevProps.aiAnnotation.pointsInfo);
      this.updateGeometryDiff = this.updateGeometryDiff
        ? {
          ...this.updateGeometryDiff,
          ...elementMeasureDiff,
          ...groupDiff,
          ...pointsDiff,
        }
        : undefined;

      this.drawingUpdatedExecutor.execute(this.onUpdatedDrawingPayload);
    }

    if (this.props.isImperial !== prevProps.isImperial) {
      this.updateGeometryDiff = undefined;
      this.onUpdatedDrawingPayload();
    }

    if (this.props.reportPages !== prevProps.reportPages) {
      this.reportCellStoreController.loadData(
        this.props.reportPages,
        this.props.match.params.projectId,
        this.setLoadedReportPage,
      );
    }

    if (this.props.multiViewStatus === null && prevProps.multiViewStatus !== null) {
      this.setReportCellController();
    }

    if (this.props.multiViewStatus !== null && prevProps.multiViewStatus === null) {
      this.setReportCellController();
      const projectId = this.props.match.params.projectId;
      this.props.set2dFullScreenMode(TwoDFullScreenMode.Drawings, projectId);
    }

    if (this.props.match.params.projectId !== prevProps.match.params.projectId) {
      this.props.setMultiViewStatus(null);
    }
  }

  public render(): React.ReactNode {
    const paneConfigs = this.state.paneConfigs;

    const isDrawingsFullScreen = this.props.isFullScreenDrawings;
    const showCollapseExpandButton = !!this.props.reportPages.length
      && this.props.multiViewStatus === null;

    return (
      <PageLayout
        toggleFullScreenDrawings={this.toggleFullScreenDrawings}
        paneConfigs={paneConfigs}
        isFullScreenDrawings={isDrawingsFullScreen}
        saveSplitterSize={this.props.saveSplitterSize}
        renderDrawingsLayout={this.renderDrawingLayout}
        renderReportPanel={this.renderReportPanel}
        showCollapseExpandButton={showCollapseExpandButton}
      />
    );
  }

  @autobind
  private renderDrawingLayout(): JSX.Element {
    const canUseCompare = this.props.ability.can(Operation.Manage, Subject.Project2DPagesCompare);
    const canUseOptimizer = this.props.ability.can(Operation.Manage, Subject.Takeoff2dFileOptimizer);

    const isDrawingsFullScreen = this.props.isFullScreenDrawings;

    return (
      <GlobalKeyboardEventsController>
        <DrawingsLayout
          canToggleFullScreen={!!this.props.reportPages.length}
          addInstancesToCellOnCreate={this.addMeasuresToCellOnCreate}
          projectId={this.getProjectId()}
          drawingEnabled={true}
          isFullScreen={isDrawingsFullScreen}
          onToggleFullScreen={this.toggleFullScreenDrawings}
          onInstancesMeasuresUpdated={this.onInstanceMeasuresUpdated}
          isSelectModeActive={this.props.focusedCell.isCanWrite}
          setSelectItemsMode={this.props.setWrite}
          onMoveToCell={this.onMoveToCell}
          onFolderToCell={this.onFolderToCell}
          onMoveTableToCell={this.onMoveTableToCell}
          onDynamicMoveToCell={this.onDynamicMoveToCell}
          onTraceLink={this.onTraceLink}
          bottomOffset={this.props.traceLink ? TRACE_LINK_HEIGHT : 0}
          getTraceLinks={this.getTraceLinks}
          onTraceLinkClick={this.onTraceLinkClick}
          onSegmentSelect={this.onSegmentSelect}
          onInstancesSelect={this.onInstanceSelect}
          onInstancesRemove={this.onRemoveInstances}
          canUseCompare={canUseCompare}
          canUseOptimizer={canUseOptimizer}
        />
      </GlobalKeyboardEventsController>
    );
  }

  @autobind
  private renderReportPanel(): JSX.Element {
    const projectIdParam = this.props.match.params.projectId;
    const isFullScreenTable = this.props.isFullScreenTable;
    const isDrawingsFullScreen = this.props.isFullScreenDrawings;
    const reportPages = this.props.reportPages.filter(page => this.state.loadedReportPages.includes(page.id));
    const isMultiViewConnected = this.props.isMultiViewConnected;
    return (
      <GlobalKeyboardEventsController>
        <ItemsViewTransactionContext.Provider value={this.viewTransaction}>
          <TwoDReportPanel
            reportPages={reportPages}
            onApiReady={this.onReportTableApiReady}
            onSheetReady={this.onSheetReady}
            projectId={projectIdParam}
            drawingsLayoutApi={this.props.drawingsLayoutApi}
            onFullscreenTableToggle={this.onFullscreenTableToggle}
            onClickTabInFullScreenDrawingsMode={this.toggleFullScreenDrawings}
            selectSheetData={this.selectSheetData}
            updateCellsValue={this.updateCells}
            selectCellValue={this.selectCellValue}
            selectCellData={this.selectCellData}
            deleteCellData={this.deleteReportCellData}
            isValidCell={this.isValidCell}
            addNewRows={this.addNewRows}
            setColumnWidth={this.setColumnWidth}
            addNewColumns={this.addNewColumns}
            isReportDataLoaded={this.state.isPageDataLoaded}
            getAllSheetsDataStore={this.getCellDataStore}
            updateCellDataOnFill={this.updateCellDataOnFill}
            isFullScreenTable={isFullScreenTable}
            isTableVisible={isDrawingsFullScreen}
            openInNewWindow={this.openNewWindow}
            disableChangeFullScreen={isMultiViewConnected}
          />
        </ItemsViewTransactionContext.Provider>
      </GlobalKeyboardEventsController >
    );
  }

  @autobind
  private openNewWindow(url: string): void {
    this.multiViewHelper.openChild(url);
  }

  @autobind
  private onFocusToComment(target: CommentaryTarget): void {
    if (CommentaryTargetTypeGuards.isDrawingWithPoint(target)) {
      if (this.props.isFullScreenTable) {
        this.onFullscreenTableToggle();
      }
      this.props.drawingsLayoutApi.scrollToPositionOnDrawing(target, target.pageId);
    } else if (CommentaryTargetTypeGuards.isDrawingSimple(target)) {
      if (this.props.drawings[target.pageId]) {
        this.props.onSelectDrawing(target.pageId);
      }
    } else if (CommentaryTargetTypeGuards.isReportPage(target)) {
      this.getReportApi().changeTableTab(target.reportPageId);
    }
  }

  @autobind
  private getTraceLinks(instanceId: string): string[] {
    if (!instanceId) {
      return [];
    }
    const { drawingGroups, drawingsToCell, dynamicGroupsToCell } = this.props;

    const cells = drawingsToCell[instanceId] || [];
    let groupCells: string[] = [];
    if (Object.keys(dynamicGroupsToCell).length) {
      const instance = this.props.aiAnnotation.geometry[instanceId];
      const groupsMap = DrawingsGroupUtils.getParentToChildMap(drawingGroups);
      let parentId = instance && instance.groupId;
      while (parentId && !(parentId in dynamicGroupsToCell)) {
        parentId = groupsMap[parentId].value.parentId;
      }
      groupCells = dynamicGroupsToCell[parentId] || [];
    }

    const totalCells = this.filterUndefinedSheetId([...cells, ...groupCells]);
    return totalCells;
  }

  @autobind
  private filterUndefinedSheetId(cells: string[]): string[] {
    const reportPagesMap = {};
    this.props.reportPages.forEach((r) => reportPagesMap[r.id] = true);
    return cells.filter((cell) => {
      const cellParts = TwoDRegexGetter.getFullCellField(cell);
      return !!reportPagesMap[cellParts.sheetId];
    });
  }

  @autobind
  private getInstancesMeasures(instancesIds: string[]): DrawingsInstanceMeasure[] {
    const { drawings, aiAnnotation, elementMeasurement } = this.props;

    return this.props.drawingsLayoutApi.getInstancesMeasures(
      instancesIds,
      drawings,
      aiAnnotation,
      elementMeasurement,
    );
  }

  @autobind
  private onMoveToCell(instanceIds: string[], type: string): void {
    const instancesMeasures = this.getInstancesMeasures(instanceIds);

    if (type === MoveToCellOptionType.count) {
      const valueCount = MoveCellHelper.getValueToInsertInCell(instancesMeasures, type);
      this.getReportApi().insertDrawingInstanceToCell(valueCount);
      return;
    }

    if (type === MoveToCellOptionType.name || type === MoveToCellOptionType.groupName) {
      this.moveToCellTextOption(instancesMeasures, type);
      return;
    }
    const value = MoveCellHelper.getValueToInsertInCell(instancesMeasures, type);
    this.getReportApi().insertDrawingInstanceToCell(value);
  }

  @autobind
  private onFolderToCell(type: string): void {
    const selectedGroupId = this.props.selectedGroups[0];
    const valueGroup = getMeasureKey(selectedGroupId, type);
    this.getReportApi().insertDrawingInstanceToCell(valueGroup);
  }

  @autobind
  private onMoveTableToCell(instanceIds: string[], preset: TablePreset): void {
    const instancesMeasures = this.getInstancesMeasures(instanceIds);
    const { columnId, rowId } = this.props.focusedCell.cell;
    const selectedCell = {
      sheetId: this.props.selectedSheetId,
      columnId,
      rowIndex: ExcelTableRowIdentification.getRowIndexFromId(rowId),
    };

    const canShowMeasure3d = this.props.ability.can(Operation.Read, Subject.Takeoff2dMeasurement3d);

    const table = MoveCellHelper.getValueToInsertInTable(
      selectedCell,
      instancesMeasures,
      this.getMaxNesting(),
      preset.settings,
      canShowMeasure3d,
    );
    this.getReportApi().insertDrawingInstanceToTable(table, false, preset.settings.showColumnHeaders);
  }

  @autobind
  private onDynamicMoveToCell(preset: TablePreset): void {
    const selectedGroupId = this.props.selectedGroups[0];
    const value = getMeasureKey(selectedGroupId, MoveToCellOptionType.dynamicTable);

    this.getReportApi().insertDrawingInstanceToCell(value, preset.settings);
  }

  private getMaxNesting(): { maxNesting: number, groupColumnCount: number } {
    const { drawingGroups, selectedInstances, selectedGroups } = this.props;
    let maxNesting = -1;
    let maxNestingGroup = null;
    const selectedGroupsSet = new Set(selectedGroups);
    const groups = drawingGroups.filter(item => selectedGroupsSet.has(item.id));
    for (const instanceId of selectedInstances) {
      const group = groups.find(g => g.measurements.includes(instanceId));
      if (!group) {
        continue;
      }
      const groupsMap = DrawingsGroupUtils.getParentToChildMap(drawingGroups);
      const nesting = DrawingsGroupUtils.getGroupNestingCount(group.id, groupsMap);
      if (maxNesting < nesting) {
        maxNestingGroup = group;
        maxNesting = nesting;
      }
    }
    const groupColumnCount = maxNestingGroup
      ? this.getSelectedParentCount(maxNestingGroup, 1, groups)
      : 1;
    return { maxNesting, groupColumnCount };
  }

  private getSelectedParentCount(
    group: DrawingsGeometryGroup,
    count: number,
    groups: DrawingsGeometryGroup[],
  ): number {
    const parentId = group.parentId;
    const groupInstances = groups.find(item => item.id === parentId);
    if (groupInstances) {
      return this.getSelectedParentCount(groupInstances, count + 1, groups);
    } else {
      return count;
    }
  }

  @autobind
  private moveToCellTextOption(instancesMeasures: DrawingsInstanceMeasure[], type: MoveToCellOptionType): void {
    const value = MoveCellHelper.getValueToInsertInRange(instancesMeasures, type);

    this.getReportApi().insertDrawingInstanceToColumn(value);
  }

  @autobind
  private onTraceLinkClick(sheetId: string, cellId: string): void {
    this.getReportApi().setFocusedCell(sheetId, cellId);
  }

  @autobind
  private onTraceLink(instanceId: string): void {
    this.props.showTraceLink(instanceId);
  }

  @autobind
  private onSegmentSelect(points: [string, string]): void {
    const multiViewConnected = this.props.multiViewStatus;
    const canEditReport = this.props.ability.can(Operation.Update, Subject.Takeoff2DReport);
    const isDrawingsFullScreen = this.props.isFullScreenDrawings;
    if (!canEditReport || (isDrawingsFullScreen && multiViewConnected === null)) {
      return;
    }

    const cell = this.props.focusedCell.cell;
    const aiAnnotation = this.props.aiAnnotation;
    const drawingInstanceId = aiAnnotation.pointsInfo[points[0]].instanceId;
    this.getReportApi().updateRowData(cell.rowId, cell.columnId, [getSegmentMeasure(points, drawingInstanceId)]);
  }

  @autobind
  private addMeasuresToCellOnCreate(instanceMeasures: DrawingsInstanceMeasure[]): void {
    const {
      isFullScreenDrawings: isDrawingsFullScreen,
      isMultiViewConnected,
      isAutoMoveToCell,
      focusedCell,
    } = this.props;

    if (isAutoMoveToCell && (!isDrawingsFullScreen || isMultiViewConnected)) {
      const { cell } = focusedCell;
      const measures = instanceMeasures.map(measure => getMeasure(measure));
      this.getReportApi().updateRowData(cell.rowId, cell.columnId, measures);
    }
  }

  @autobind
  private onInstanceSelect(instancesIds: string[]): void {
    const { cell, isCanWrite } = this.props.focusedCell;
    if (isCanWrite) {
      if (!instancesIds.length) {
        this.props.setWrite(false);
      } else if (this.props.drawingsLayoutApi) {
        const reduxValue = this.props.drawingsMeasure[instancesIds[0]];
        const drawingsInstanceMeasure = reduxValue
          ? reduxValue
          : this.getInstancesMeasures(instancesIds)[0];
        this.getReportApi().updateRowData(cell.rowId, cell.columnId, [getMeasure(drawingsInstanceMeasure)]);
      }
    }
  }

  @autobind
  private onReportTableApiReady(api: ReportTableApi): void {
    if (this.props.isMultiViewConnected) {
      const apiWithMultiViewSender = MultiViewersStates.extendApiWithMultiViewState(
        api,
        'tableApi',
        this.multiViewHelper,
        whiteApiList,
      );
      this.reportPanelApi = apiWithMultiViewSender;
    } else {
      this.reportPanelApi = api;
    }
    this.originReportPanelApi = api.getApi();
    this.reportCellStoreController.initReportCellStores(this.props.match.params.projectId);
  }

  @autobind
  private async onInstanceMeasuresUpdated(measures: DrawingsInstanceMeasure[]): Promise<void> {
    this.reportCellStoreController.updateCellsByMeasure(measures);
  }

  @autobind
  private toggleFullScreenDrawings(): void {
    const projectId = this.props.match.params.projectId;
    this.props.set2dFullScreenMode(this.props.isFullScreenDrawings ? null : TwoDFullScreenMode.Drawings, projectId);
    this.props.setWrite(false);
  }

  @autobind
  private onFullscreenTableToggle(): void {
    const projectId = this.props.match.params.projectId;
    this.props.set2dFullScreenMode(this.props.isFullScreenTable ? null : TwoDFullScreenMode.Report, projectId);
  }

  @autobind
  private getPaneConfig(): SpliterPaneConfig[] {
    const { isFullScreenDrawings, defaultTableSize } = this.props;
    const tableWidth = defaultTableSize || defaultTablePaneSizeHorizontal;

    return isFullScreenDrawings
      ? getFullScreenConfig()
      : getDefaultPaneConfig(tableWidth);
  }

  @autobind
  private getProjectId(): number {
    return parseInt(this.props.match.params.projectId, 10);
  }

  @autobind
  private getDrawingsInstancesInCell(): string[] {
    return Object.keys(this.props.drawingsToCell);
  }

  @autobind
  private isDrawingPayloadUpdated(prevProps: PageProps): boolean {
    return prevProps.aiAnnotation !== this.props.aiAnnotation
      || prevProps.drawings !== this.props.drawings
      || prevProps.drawingGroups !== this.props.drawingGroups
      || prevProps.entities !== this.props.entities
      || prevProps.elementMeasurement !== this.props.elementMeasurement;
  }

  @autobind
  private onUpdatedDrawingPayload(): void {
    const totalDiff = this.updateGeometryDiff;
    this.updateGeometryDiff = {};
    let usedAndUpdatedMeasure = [];
    const instanceIdsInCell = this.getDrawingsInstancesInCell();
    if (totalDiff) {
      Object.keys(totalDiff).forEach(k => {
        if (k.includes('|')) {
          const points = k.split('|');
          const pointDiff = totalDiff[points[0]];
          const drawingInstanceId = pointDiff?.instanceId
            ? pointDiff.instanceId
            : this.props.aiAnnotation.pointsInfo[points[0]].instanceId;
          delete totalDiff[k];
          totalDiff[drawingInstanceId] = {};
        }
      });
      const groupsSet = new Set(this.props.drawingGroups.map(g => g.id));
      usedAndUpdatedMeasure = instanceIdsInCell.filter(e => e in totalDiff || groupsSet.has(e));
    } else {
      usedAndUpdatedMeasure = instanceIdsInCell;
    }

    this.reportCellStoreController.onDrawingsLoaded(usedAndUpdatedMeasure, this.props.dynamicGroupsToCell);
  }

  @autobind
  private getDrawingPayload(): DrawingPayload {
    return {
      drawingsLayoutApi: this.props.drawingsLayoutApi,
      drawingsInfo: this.props.drawings,
      aiAnnotation: this.props.aiAnnotation,
      drawingGroups: this.props.drawingGroups,
      entities: this.props.entities,
      elementMeasurement: this.props.elementMeasurement,
    };
  }

  @autobind
  private isImperial(): boolean {
    return this.props.isImperial;
  }

  @autobind
  private getDrawingsToCells(id: string | string[]): string[] {
    const key = Array.isArray(id) ? id.join(',') : id;
    return this.props.drawingsToCell[key] || [];
  }

  @autobind
  private refreshTable(force: boolean = false): void {
    this.getReportApi().refreshCell(force);
  }

  @autobind
  private getCellToCells(id: string): string[] {
    return this.props.cellToCells[id] || [];
  }

  @autobind
  private selectSheetData(id: string): SheetDataStore {
    return this.reportCellStoreController.selectSheetData(id);
  }

  @autobind
  private updateCells(payload: Array<SheetUpdateCells<UpdateCellData[]>>, skipRefresh: boolean): void {
    this.reportCellStoreController.updateCells(payload, skipRefresh);
  }

  @autobind
  private selectCellValue(sheetId: string, columnId: string, rowId: string): string | number {
    return this.reportCellStoreController.selectSheetValue(sheetId, columnId, rowId);
  }

  @autobind
  private deleteReportCellData(sheetId: string): void {
    if (this.props.reportPages.length === 1) {
      this.multiViewHelper.closeSecondWindow();
    }
    this.reportCellStoreController.deleteCellDataStore(sheetId);
    this.deleteLoadedReportPage(sheetId);
  }

  @autobind
  private selectCellData(sheetId: string, columnId: string, rowId: string): string {
    return this.reportCellStoreController.selectCellData(sheetId, columnId, rowId);
  }

  @autobind
  private isValidCell(cellId: string): boolean {
    return this.reportCellStoreController.isValidCell(cellId);
  }

  @autobind
  private onSheetRowsDataUpdated(
    sheetId: string,
    payload: UpdateCellDataTransaction,
    updatedCells: UpdatedCell[],
  ): void {
    this.getReportApi().updateRowDataTransaction(sheetId, payload);
    this.updateCellsRefs(updatedCells);
  }

  @autobind
  private addNewRows(sheetId: string, count: number): void {
    this.reportCellStoreController.addNewRows(sheetId, count);
  }

  @autobind
  private addNewColumns(sheetId: string, count: number): void {
    this.reportCellStoreController.addNewColumns(sheetId, count);
  }

  @autobind
  private setColumnWidth(sheetId: string, payload: Array<{ columnId: string, width: number }>): void {
    this.reportCellStoreController.setColumnWidthBulck(sheetId, payload);
  }

  @autobind
  private onSheetColumnsDataUpdated(sheetId: string, columns: Ag.ColDef[]): void {
    this.getReportApi().updateColumns(sheetId, columns);
  }

  @autobind
  private onSheetReady(sheetId: string): void {
    const { rows } = this.reportCellStoreController.selectSheetData(sheetId);
    const updatedCells = this.reportCellStoreController.getUpdatedCells(sheetId);
    this.getReportApi().setRowData(sheetId, rows);
    this.updateCellsRefs(updatedCells);
  }

  private updateCellsRefs(updatedCells: UpdatedCell[]): void {
    const { cellToCellMap, drawingInstanceToCellMap, dynamicGroupsToCellMap } = getRefMaps(
      updatedCells,
      this.props.drawingsToCell,
      this.props.dynamicGroupsToCell,
      this.props.cellToCells,
    );
    this.props.updateCellToCell(cellToCellMap);
    this.props.updateDrawingsToCell(drawingInstanceToCellMap);
    this.props.updateDynamicGroupsToCell(dynamicGroupsToCellMap);
    const drawingInstanceCellSet = new Set(Object.keys(drawingInstanceToCellMap));
    const cellIdWithDrawing = arrayUtils.filterMap(
      updatedCells,
      c => drawingInstanceCellSet.has(c.fullCellId),
      c => c.fullCellId,
    );
    this.reportCellStoreController.updateCellValueWithDrawings(cellIdWithDrawing);
    this.reportCellStoreController.updateDynamicCells(dynamicGroupsToCellMap);
  }

  @autobind
  private getDynamicTable(groupId: string, cell: TwoDFullCellId, settings: TableSettings): Record<string, string> {
    const group = this.props.drawingGroups.find(gr => gr.id === groupId);
    if (group) {
      const groupsMap = DrawingsGroupUtils.getParentToChildMap(this.props.drawingGroups);
      const measurements = DrawingsGroupUtils.getAllInnerMeasurements(group, groupsMap, gr => !!gr);
      const instancesMeasures = this.getInstancesMeasures(measurements);
      const { columnId, rowId, sheetId } = cell;
      const startCell = {
        sheetId,
        columnId,
        rowIndex: ExcelTableRowIdentification.getRowIndexFromId(rowId) + 1,
      };
      const canShowMeasure3d = this.props.ability.can(Operation.Read, Subject.Takeoff2dMeasurement3d);

      const table = MoveCellHelper.getValueToInsertInTable(
        startCell,
        instancesMeasures,
        this.getDynamicTableProps(measurements, group.id, groupsMap),
        settings,
        canShowMeasure3d,
      );

      return this.getReportApi().getDynamicCellsList(table, cell, settings.showColumnHeaders);
    }
    return {};
  }

  private getDynamicTableProps(
    instancesMeasures: string[],
    groupId: string,
    groupsMap: ParentToChildMap,
  ): { maxNesting: number, groupColumnCount: number } {
    const groupNesting = DrawingsGroupUtils.getGroupNestingCount(groupId, groupsMap);
    const maxNesting =
      DrawingsGroupUtils.getMeasurementNestingCount(instancesMeasures, groupsMap, this.props.drawingGroups);
    return { maxNesting, groupColumnCount: maxNesting - groupNesting + 1 };
  }

  @autobind
  private onReportSheetLoaded(reportPages: ReportPage[]): void {
    const pageIds = reportPages.map(page => page.id);
    if (pageIds.length) {
      this.props.setShowSheetsIds([reportPages[0].id]);
      this.props.setSelectedSheetId(reportPages[0].id);
      this.props.setPages(reportPages);
    }
    this.setState({ isPageDataLoaded: true, loadedReportPages: pageIds });
  }

  @autobind
  private onReportDataLoaded(pageId: string, etag: number): void {
    this.props.setReportEtag(pageId, etag);
  }

  @autobind
  private setIsGetPageData(isGetPageData: boolean): void {
    this.props.setIsGetPageData(isGetPageData);
  }

  @autobind
  private setLoadedReportPage(sheetId: string): void {
    this.setState((s) => ({ loadedReportPages: s.loadedReportPages.concat(sheetId) }));
  }

  @autobind
  private deleteLoadedReportPage(sheetId: string): void {
    this.setState(s => ({ loadedReportPages: s.loadedReportPages.filter(p => p !== sheetId) }));
  }

  @autobind
  private getCellDataStore(): CellDataStore {
    return this.reportCellStoreController.getReportData();
  }

  @autobind
  private onRemoveInstances(instancesIds: string[]): void {
    this.reportCellStoreController.updateCellsByRemoveInstancesIds(instancesIds);
    this.props.removeAssign(instancesIds);
  }

  @autobind
  private updateCellDataOnFill(
    sheetId: string,
    columnId: string,
    rowId: string,
    data: string | number,
  ): void {
    this.reportCellStoreController.updateCellDataOnFill(sheetId, columnId, rowId, data);
  }

  @autobind
  private setReportCellController(): void {
    if (this.props.isMultiViewConnected) {
      this.reportCellStoreController = extendWithCellStore
        .bind(this.multiViewHelper)(new ReportCellController(this.reportCellControllerProps));
      const apiWithMultiViewSender = MultiViewersStates.extendApiWithMultiViewState(
        this.reportPanelApi,
        'tableApi',
        this.multiViewHelper,
        whiteApiList,
      );
      this.reportPanelApi = apiWithMultiViewSender;
    } else {
      if (!this.reportCellStoreController) {
        this.reportCellStoreController = new ReportCellController(this.reportCellControllerProps);
        this.reportCellStoreControllerOrigin = this.reportCellStoreController;
      } else {
        this.reportCellStoreController = this.reportCellStoreControllerOrigin;
        this.reportCellStoreController.dropState();
        this.reportCellStoreController.setProps(this.reportCellControllerProps);
        this.reportCellStoreController.initReportCellStores(this.props.match.params.projectId, true);
      }
    }
  }

  @autobind
  private getReportApi(): ReportTableApi {
    if (this.props.isMultiViewConnected) {
      return this.reportPanelApi;
    } else {
      return this.originReportPanelApi;
    }
  }

  @autobind
  private startCalcCells(total: number, title: string): void {
    this.props.startCalcCells(total, title);
  }

  @autobind
  private updateCalcCellProgress(finished: number, title: string): void {
    this.props.updateCalcCellProgress(finished, title);
  }

  @autobind
  private closeProgressbar(title: string): void {
    this.props.closeProgressbar(title);
  }

  private getMap<T extends { id: string }>(list: T[]): Record<string, T> {
    return list.reduce((total, current) => {
      total[current.id] = current;
      return total;
    }, {});
  }

  @autobind
  private getDrawingInfo(): DrawingInfo {
    const { drawingGroups, aiAnnotation, drawings, files, drawingsLayoutApi } = this.props;
    const groupsMap = this.getGroupMap();
    return {
      aiAnnotation,
      groups: drawingGroups,
      drawingsInfo: drawings,
      files,
      groupsMap,
      getInstancesMeasures: drawingsLayoutApi.getInstancesMeasuresSimple,
    };
  }

  private getGroupMap(): Record<string, DrawingsGeometryGroup> {
    const { drawingGroups } = this.props;
    if (drawingGroups !== this.prevGroup) {
      const groupsMap: Record<string, DrawingsGeometryGroup> = drawingGroups.reduce((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {});
      this.prevGroupMap = groupsMap;
      this.prevGroup = drawingGroups;
    }

    return this.prevGroupMap;
  }

  @autobind
  private getAssignInfo(): AssignInfo {
    const {
      assignedPia,
      isImperial,
      originProperties,
      elementMeasurement,
      withUnit,
      showCode,
    } = this.props;
    return {
      drawingElementAssign: assignedPia,
      isImperial,
      originProperties,
      elementMeasurement,
      withUnit,
      showCode,
    };
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    clearReportTempalte: () => dispatch(TwoDReportTemplateActions.clearProjectState()),
    loadDrawings: () => dispatch(DrawingsActions.loadDrawingsData()),
    loadCommentsData: () => {
      dispatch(TwoDCommentsActions.loadCommentsRequest());
      dispatch(TwoDCommentsActions.connectRealtime());
    },
    setCellToUpdate: (value: CellToUpdatePayload[]) => dispatch(TwoDActions.setCellToUpdate(value)),
    forceSaveAndDropDrawingState: () => {
      dispatch(DrawingsUpdateActions.forceSave());
      dispatch(DrawingsActions.dropState());
      dispatch(TwoDCommentsActions.dropState());
    },
    dropReportState: () => dispatch(TwoDActions.dropState()),
    setWrite: value => dispatch(TwoDActions.setWrite(value)),
    showTraceLink: (instanceId: string) => dispatch(TwoDActions.showTraceLink(instanceId)),
    set2dFullScreenMode: (type: TwoDFullScreenMode, projectId: string) =>
      dispatch(PersistedStorageActions.set2dFullScreenMode({ type, projectId })),
    setTableViewType: (tableViewType: Arrangement) => dispatch(TwoDActions.setTableViewType(tableViewType)),
    updateCellToCell: (payload: Record<string, string[]>) => dispatch(TwoDActions.updateCellToCell(payload)),
    updateDrawingsToCell: (payload: Record<string, string[]>) => dispatch(TwoDActions.updateDrawingsToCell(payload)),
    updateDynamicGroupsToCell: (payload) => dispatch(TwoDActions.updateDynamicGroupsToCell(payload)),
    setShowSheetsIds: (reportPagesIds: string[]) => dispatch(TwoDActions.setShowSheetsIds(reportPagesIds)),
    setSelectedSheetId: (selectedSheetId: string) => dispatch(TwoDActions.setSelectedSheetId(selectedSheetId)),
    setPages: (reportPages: ReportPage[]) => dispatch(TwoDActions.setPages(reportPages)),
    addNewSheet: (reportPage: ReportPage) => dispatch(TwoDActions.addNewSheet(reportPage)),
    saveSplitterSize: (size: number) => {
      dispatch(PersistedStorageActions.saveSplitterSize(ReportPaneSize, size));
    },
    setCalculatedProperties: (update) => dispatch(TwoDActions.setCalculatedPia(update)),
    fetchAssignPia: (fetchPiaDatabase: boolean) => {
      dispatch(TwoDActions.fetchAssignPia());
      if (fetchPiaDatabase) {
        dispatch(TwoDDatabaseActions.fetchDatabaseRequest());
      }
    },
    removeAssign: (instancesIds) => dispatch(TwoDActions.removeAssignPia(instancesIds)),
    setReportEtag: (pageId, etag) => dispatch(TwoDActions.setReportPageEtag(pageId, etag)),
    onSelectDrawing: drawingId => {
      dispatch(DrawingsActions.selectDrawing(drawingId));
      dispatch(DrawingsActions.saveSelectedDrawingsState(drawingId));
    },
    setIsGetPageData: (isGetPageData) => dispatch(TwoDActions.setIsGetPageData(isGetPageData)),
    setSkipCalcPia: () => dispatch(TwoDActions.setIsSkipCalcPia(true)),
    startCalcCells: (total, title) => dispatch(ProgressActions.addOrUpdateProgress({
      progressKey: ProgressUtils.REPORT_DATA_PROGRESS_KEY,
      progressBar: {
        title,
        type: ProgressBarType.Count,
        finished: 0,
        total,
      },
    })),
    updateCalcCellProgress: (finished, title) => dispatch(ProgressActions.updateProgress({
      progressKey: ProgressUtils.REPORT_DATA_PROGRESS_KEY,
      progressBar: {
        title,
        finished,
      },
    })),
    closeProgressbar: (progressBarTitle) => dispatch(ProgressActions.removeProgressBar({
      progressKey: ProgressUtils.REPORT_DATA_PROGRESS_KEY,
      progressBarTitle,
    })),
    setTwoDElementViewHandles: (payload) => dispatch(TwoDElementViewActions.setCallbacks(payload)),
    setMultiViewStatus: (status) => dispatch(multiViewActions.setConnection(status)),
  };
}

function mapStateToProps(state: State): StateProps {
  const drawings = state.drawings;
  const projectId = state.projects.currentProject.id.toString();
  const fullScreenMode = PersistedStorageSelectors.selectTwoDFullScreenMode(state.persistedStorage, projectId);

  return {
    focusedCell: state.twoD.focusedCell,
    drawingsToCell: state.twoD.drawingInstanceToCell,
    dynamicGroupsToCell: state.twoD.dynamicGroupsToCell,
    traceLink: state.twoD.traceLink,
    isFullScreenDrawings: fullScreenMode === TwoDFullScreenMode.Drawings,
    isFullScreenTable: fullScreenMode === TwoDFullScreenMode.Report,
    isAutoMoveToCell: state.persistedStorage.isAutoMoveToCell,
    cellToCells: state.twoD.cellToCell,
    isImperial: state.account.settings.isImperial,
    selectedInstances: drawings.selectedInstances,
    selectedGroups: drawings.selectGeometryGroup,
    elementMeasurement: drawings.elementMeasurement,
    drawings: drawings.drawingsInfo,
    aiAnnotation: drawings.aiAnnotation,
    currentDrawing: drawings.currentDrawingInfo,
    drawingsMeasure: drawings.elementMeasurement,
    drawingGroups: drawings.drawingGeometryGroups,
    entities: drawings.files.entities,
    reportPages: state.twoD.reportPages,
    selectedSheetId: state.twoD.selectedSheetId,
    defaultTableSize: state.persistedStorage.splitterPaneSize &&
      state.persistedStorage.splitterPaneSize[ReportPaneSize],
    assignedPia: state.twoD.assignPia,
    calculatedPia: state.twoD.calculatedPia,
    originProperties: state.twoDDatabase.properties,
    shouldSkipCalcPia: isItemViewVisible(state),
    files: drawings.files,
    isMultiViewConnected: state.multiView.connectionStatus !== null,
    multiViewStatus: state.multiView.connectionStatus,
    withUnit: state.persistedStorage.showTableUnits,
    showCode: state.twoD.viewsConfigs && state.twoD.viewsConfigs[state.twoD.selectedSheetId]?.createCodeColumn,
  };
}

export const TwoDPage = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(
    withAnalyticsContext(withCommentsApiContext(withDrawingsLayoutApiContext(TwoDPageComponent))),
  ));
