import * as monolite from 'monolite';

import { RequestStatus } from 'common/enums/request-status';
import { SubscriptionEditorState } from 'common/interfaces/account/subscription-editor-form-state';
import { MonoliteHelper } from 'common/monolite';
import { Account, StateStatus } from '../../common/interfaces/account';
import { ReducerMethods } from '../../common/interfaces/reducer-methods';
import { ACCOUNT_INITIAL_STATE, SUBSCRIPTION_EDIT_FORM_STATE } from '../../reducers/account';
import {
  ChangeCompanySubscriptionPayload, SubscribeErrorPayload, ValidationField,
} from './actions/payloads';
import { AccountActionTypes } from './actions/types';
import { Company } from './interfaces/company';
import { MotivationPopupSettings } from './interfaces/motivation-popup-settings';
import { UserInfoResponse } from './interfaces/user-info-response';


const saveUserInfo = (state: Account, payload: UserInfoResponse): Account => {
  const statePatch: Partial<Account> = {
    email: payload.email,
    intercomUserHash: payload.intercomUserHash,
    firstName: payload.firstName,
    lastName: payload.lastName,
    id: payload.id,
    isAdmin: payload.isAdmin,
    settings: payload.settings || state.settings,
    ownedCompany: payload.company,
    hasAvatar: payload.hasAvatar,
    showQuestionnaire: payload.showQuestionnaire,
  };

  return new MonoliteHelper(state)
    .set(_ => _, { ...state, ...statePatch })
    .get();
};

const singInFailed = (state: Account, payload: string): Account => {
  state = monolite.set(state, (_) => _.registrationRequestStatus)(
    RequestStatus.Loaded,
  );
  const status: StateStatus = { hasError: true, message: payload };
  return monolite.set(state, (_) => _.status)(status);
};

const setAvatarFile = (
  state: Account,
  payload: File,
): Account => {
  return monolite.set(state, (_) => _.restrictAvatarFile)(payload);
};

const resetPasswordStatus = (state: Account, payload: RequestStatus): Account => {
  return monolite.set(state, (_) => _.resetPasswordStatus)(payload);
};

const fetchCompanies = (state: Account): Account => {
  return new MonoliteHelper(state)
    .set(_ => _.statuses.fetchCompanies, RequestStatus.Loading)
    .get();
};

const fetchCompaniesSuccess = (state: Account, companies: Company[]): Account => {
  const selectedCompanyId = state.selectedCompany && state.selectedCompany.id;
  const selectedCompany = companies.find(x => x.id === selectedCompanyId);

  const stateUpdater = new MonoliteHelper(state)
    .set(_ => _.companies, companies)
    .set(_ => _.selectedCompany, selectedCompany)
    .set(_ => _.statuses.fetchCompanies, RequestStatus.Loaded);

  return stateUpdater.get();
};

const selectCompany = (state: Account, company: Company): Account => {
  return monolite.set(state, (_) => _.selectedCompany)(company);
};

const logOut = (state: Account): Account => {
  return {
    ...ACCOUNT_INITIAL_STATE,
    confirmResponseCode: state.confirmResponseCode,
    isInvitation: state.isInvitation,
    statuses: {
      ...state.statuses,
      confirmationStatus: state.statuses.confirmationStatus,
    },
  };
};

const createCompany = (state: Account): Account => {
  return new MonoliteHelper(state)
    .set((_) => _.statuses.createCompany, RequestStatus.Loading)
    .get();
};

const createCompanySucceeded = (state: Account): Account => {
  return new MonoliteHelper(state)
    .set((_) => _.statuses.createCompany, RequestStatus.Loaded)
    .get();
};

const getInitialAppData = (state: Account): Account => {
  return new MonoliteHelper(state)
    .set((_) => _.initialAppDataStatus, RequestStatus.Loading)
    .get();
};

const setInitialAppDataStatus = (state: Account, payload: RequestStatus): Account => {
  return new MonoliteHelper(state)
    .set((_) => _.initialAppDataStatus, payload)
    .get();
};

export const accountReducerMethods: ReducerMethods<Account> = {
  [AccountActionTypes.SIGN_IN_FAILED]: singInFailed,
  [AccountActionTypes.GET_INFO_SUCCEEDED]: saveUserInfo,
  [AccountActionTypes.SET_AVATAR_FILE]: setAvatarFile,
  [AccountActionTypes.SET_RESET_PASSWORD_STATUS]: resetPasswordStatus,
  [AccountActionTypes.FETCH_COMPANIES]: fetchCompanies,
  [AccountActionTypes.FETCH_COMPANIES_SUCCEEDED]: fetchCompaniesSuccess,
  [AccountActionTypes.SELECT_COMPANY]: selectCompany,
  [AccountActionTypes.LOG_OUT]: logOut,
  [AccountActionTypes.CREATE_COMPANY_REQUEST]: createCompany,
  [AccountActionTypes.CREATE_COMPANY_SUCCEEDED]: createCompanySucceeded,
  [AccountActionTypes.GET_INITIAL_APP_DATA]: getInitialAppData,
  [AccountActionTypes.SET_INITIAL_APP_DATA_STATUS]: setInitialAppDataStatus,
  [AccountActionTypes.CHANGE_COMPANY_SUBSCRIPTION]: (
    s,
    { companyId, subscription, subscriptionType }: ChangeCompanySubscriptionPayload,
  ) => {
    const stateUpdater = new MonoliteHelper(s);
    const companyIndex = s.companies.findIndex(c => c.id === companyId);
    if (companyIndex === -1) {
      return s;
    }

    const updatedSubscription = {
      ...s.companies[companyIndex].subscriptions[subscriptionType],
      ...subscription,
    };

    stateUpdater.set(_ => _.companies[companyIndex].subscriptions[subscriptionType], updatedSubscription);

    if (s.selectedCompany.id === companyId) {
      stateUpdater.set(_ => _.selectedCompany.subscriptions[subscriptionType], updatedSubscription);
    }

    return stateUpdater.get();
  },
  [AccountActionTypes.LOAD_MOTIVATION_SETTINGS_SUCCEED]: (s, payload: MotivationPopupSettings) => {
    return new MonoliteHelper(s)
      .set(_ => _.motivationPopupSettings, payload)
      .get();
  },
  [AccountActionTypes.TOGGLE_IMPERIAL_METRIC]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.settings.isImperial, !s.settings.isImperial)
      .get();
  },
  [AccountActionTypes.SET_CUSTOM_COLORS]: (s, colors: string[]) => {
    return new MonoliteHelper(s)
      .set(_ => _.settings.colors, colors)
      .get();
  },
  [AccountActionTypes.REMOVE_USER_COLOR]: (s, color: string) => {
    return new MonoliteHelper(s)
      .setFilter(_ => _.settings.colors, _ => _ !== color)
      .get();
  },
  [AccountActionTypes.SET_SUBSCRIBE_ERROR]: (s, { field, errorText }: SubscribeErrorPayload) => {
    const helper = new MonoliteHelper(s);
    if (field === ValidationField.VatNumber) {
      helper.set(_ => _.subscriptionValidation.vatNumberValidation, errorText);
    }

    if (field === ValidationField.RenewSubscription) {
      helper.set(_ => _.subscriptionValidation.renewValidation, errorText);
    }
    return helper.get();
  },
  [AccountActionTypes.TOGGLE_SHOW_LOADING]: (s, payload: boolean) => {
    return new MonoliteHelper(s)
      .set(_ => _.showLoadingCreateSubscription, payload)
      .get();
  },
  [AccountActionTypes.SET_AVATAR_SUCCEED]: (s) => {
    return new MonoliteHelper(s).set(_ => _.hasAvatar, true).get();
  },
  [AccountActionTypes.REMOVE_AVATAR]: (s) => {
    return new MonoliteHelper(s).set(_ => _.hasAvatar, false).get();
  },
  [AccountActionTypes.SET_SHOW_QUESTIONNAIRE]: (s, payload: boolean) => {
    return new MonoliteHelper(s).set(_ => _.showQuestionnaire, payload).get();
  },
  [AccountActionTypes.SET_SUBSCRIPTION_EDIT_FORM_STATE]: (s, payload: Partial<SubscriptionEditorState>) => {
    const helper = new MonoliteHelper(s);
    for (const key in payload) {
      helper.set(_ => _.subscriptionEditorState[key], payload[key]);
    }

    return helper.get();
  },
  [AccountActionTypes.RESET_SUBSCRIPTION_EDIT_FORM_STATE]: (s) => {
    return new MonoliteHelper(s)
      .set(_ => _.subscriptionEditorState, SUBSCRIPTION_EDIT_FORM_STATE)
      .get();
  },
  [AccountActionTypes.SET_COMPANY_LOGO_TEMPORARY_KEY]: (s, payload: string) => {
    return new MonoliteHelper(s).set(_ => _.companyLogoTemporaryKey, payload).get();
  },
};
