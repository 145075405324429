import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { State } from 'common/interfaces/state';
import { useAnnotationTryCreatePermission, useMeasurementsAbilities } from '../../hooks';
import { getOptions } from '../helpers';
import { InstrumentGroupInfo, InstrumentInfo } from '../interfaces';

export function useOptions(): Array<InstrumentInfo | InstrumentGroupInfo> {

  const { canEditMeasurements, canViewMeasurements } = useMeasurementsAbilities();
  const canTryCreateAnnotation = useAnnotationTryCreatePermission();
  const isAutoComplete = useSelector<State, boolean>(state => state.persistedStorage.drawingAutocomplete);
  const canUseAiSuggestForLines = useAbility(Operation.Manage, Subject.Takeoff2dMeasurementAutocomplete);
  const canUseWizzard = useAbility(Operation.Manage, Subject.Takeoff2dWizzardShow);
  const canUseOneClickLine = useAbility(Operation.Manage, Subject.Takeoff2dOneClickLineShow);
  const canUseFinder = useAbility(Operation.Manage, Subject.Takeoff2dWizzardFinderShow);

  const options = useMemo(() => {
    return getOptions({
      editPermission: canEditMeasurements,
      viewPermission: canViewMeasurements,
      annotationsTryCreatePermission: canTryCreateAnnotation,
      isAiSuggestEnabled: isAutoComplete,
      aiSuggestPermission: canUseAiSuggestForLines,
      canUseFinder,
      canUseWizzard,
      canUseOneClickLine,
    });
  }, [
    canEditMeasurements,
    canViewMeasurements,
    canTryCreateAnnotation,
    isAutoComplete,
    canUseAiSuggestForLines,
    canUseFinder,
    canUseWizzard,
    canUseOneClickLine,
  ]);

  return options;
}
