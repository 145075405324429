import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Action, Dispatch } from 'redux';


import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { ProjectType } from 'common/constants/project-type';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit/dialogs';
import { arrayUtils } from 'common/utils/array-utils';
import { ShortcutDialog } from 'components/dialog/shortcut-dialog';
import {
  ButtonShareFastNavigation,
} from 'routes/quantity-take-off-2d/route-fast-navigation/button-share-fast-navigation';
import {
  MenuProjectFastNavigation,
} from 'routes/quantity-take-off-2d/route-fast-navigation/menu-project-fast-navigation/menu-project-fast-navigation';
import {
  MenuQuestionsFastNavigation,
} from 'routes/quantity-take-off-2d/route-fast-navigation/menu-questions-fast-navigation';
import { pushItemToUrl } from 'unit-2d-database/helpers/current-tab-getters';
import { FastNavigationLinks } from '../../../../units/2d/constants/fast-navigation-links';
import { Content } from '../content-manager';
import { Styled } from './styled';
import { TabLink } from './tab-link';

interface StateProps {
  projectType: ProjectType;
  showSubscriptionDialog: boolean;
}

interface DispatchProps {
  closeDialog: (dialogName: string) => void;
}

interface OwnProps<T> {
  activeNavigationType: T | string;
}

interface Props<T> extends StateProps, DispatchProps, OwnProps<T>, RouteComponentProps<{}>, AbilityAwareProps { }

export const getFastNavigation = (contents: Content[]): React.ComponentType<OwnProps<any>> => {
  class FastNavigationComponent<T> extends React.Component<Props<T>> {
    public componentDidMount(): void {
      const { activeNavigationType, history, showSubscriptionDialog } = this.props;
      if (!showSubscriptionDialog) {
        pushItemToUrl(activeNavigationType, history.push);
      }
    }

    public render(): JSX.Element {
      const { activeNavigationType, projectType, ability, closeDialog } = this.props;
      const isFastNavigationProject = activeNavigationType === FastNavigationLinks.Project;
      const tabs = this.getTabs();
      const showButtonShare = isFastNavigationProject
        && projectType === ProjectType.Project2d
        && ability.can(Operation.Manage, Subject.ShareProjects);

      return (
        <RenderIf condition={true}>
          <Styled.Container>
            <Styled.TabsWrapper>
              {tabs}
            </Styled.TabsWrapper>
            <Styled.MenuProjectFastContainer>
              <MenuProjectFastNavigation showMenu={isFastNavigationProject}/>
            </Styled.MenuProjectFastContainer>
            <Styled.ButtonsContainer>
              <RenderIf condition={isFastNavigationProject}>
                <MenuQuestionsFastNavigation />
              </RenderIf>
              <RenderIf condition={showButtonShare}>
                <ButtonShareFastNavigation />
              </RenderIf>
            </Styled.ButtonsContainer>
          </Styled.Container>
          <ShortcutDialog onCloseDialog={closeDialog}/>
        </RenderIf>
      );
    }

    private getTabs(): JSX.Element[] {
      const { activeNavigationType } = this.props;

      return arrayUtils.filterMap(contents, c => !c.isDisabled(), (link) => {
        const isActive = link.type === activeNavigationType;
        const isNeedUpgrade = link.isUpgrade && link.isUpgrade();
        const handleClick = isNeedUpgrade
          ? ConstantFunctions.doNothing
          : link.onClick;

        return (
          <UpgradeWrapper isNeedUpdate={isNeedUpgrade}>
            <TabLink
              key={link.title}
              onClick={handleClick}
              isActive={isActive}
              title={link.title}
              isDisabled={link.isDisabled() || isNeedUpgrade}
            />
          </UpgradeWrapper>
        );
      });

    }
  }
  return withRouter(connect(mapStateToProps, mapDispatchToProps)(withAbilityContext(FastNavigationComponent)));
};


function mapStateToProps(state: State): StateProps {
  return {
    projectType: state.projects.currentProject?.type,
    showSubscriptionDialog: Boolean(state.router.location.query?.billingDetails),
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): DispatchProps {
  return {
    closeDialog: (dialogName) => dispatch(KreoDialogActions.closeDialog(dialogName)),
  };
}
