import autobind from 'autobind-decorator';
import GUI from 'lil-gui';
import * as Stats from 'stats-js';
import * as THREE from 'three';
import {} from 'three/examples/jsm/lines/LineGeometry';

import { AsyncArrayUtils } from 'common/utils/async-array-util';
import { delay } from 'common/utils/delay';
import { objectUtils } from 'common/utils/object-utils';
import { ThreeDSettings } from 'persisted-storage/interfaces/state';
import { ContextObserver, ContextObserverWithPrevious } from '../../drawings-contexts';
import { DrawingsInstanceSetFilter } from '../../drawings-geometry/drawings-helpers';
import { HoverInstanceChangeEvent } from '../../drawings-geometry/interfaces';
import { DrawingsGeometryInstance, DrawingsGeometryStrokedType, ShortPointDescription } from '../../interfaces';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { MouseController } from './components/mouse-controller';
import { THREED_DEFAULTS } from './constants';
import { PdfPlane, Polygon, Polyline } from './geometries';
import { Geometry } from './geometries/geometry';
import { EngineObject } from './interfaces';
import { OrbitControls } from './plugins/orbit-controls';
import { createRenderer } from './utils';
import { addAmbientLightSettings } from './utils/add-light-settings';
import { MaterialSettings, addMaterialSettings } from './utils/add-material-settings';

interface Config {
  root: HTMLDivElement;
  scaleObserver: ContextObserver<number>;
  onSelect: (id: string, e: MouseEvent) => void;
  onHover: (e: HoverInstanceChangeEvent) => void;
  settingsObserver: ContextObserverWithPrevious<ThreeDSettings>;
  showThicknessContextObserver: ContextObserver<boolean>;
  onCameraChanged?: () => void;
}

interface EngineVisualData {
  geometries: Record<string, DrawingsGeometryInstance>;
  points: Record<string, ShortPointDescription>;
  selectedGeometries: string[];
  hiddenIds: string[];
  filteredIds: string[];
  instancesIds: string[];
}

export class ThreeEngine implements EngineObject {
  private _instancesFilter: DrawingsInstanceSetFilter = new DrawingsInstanceSetFilter(null);
  private _hiddenInstancesFilter: DrawingsInstanceSetFilter = new DrawingsInstanceSetFilter(null);
  private _config: Config;
  private _camera: THREE.Camera;
  private _scene: THREE.Scene;
  private _renderer: THREE.WebGLRenderer;
  private _controls: any;
  private _background: PdfPlane;
  private _gui: GUI;
  private _cameraSettings = {
    fov: 32,
    near: 0.1,
    far: 10000,
  };
  private _materialLookSettings: MaterialSettings;
  private _stats: Stats;
  private _mouseController: MouseController;
  private _cameraHasBeenChanged: boolean = false;
  private _destroyed: boolean = false;

  private _geometries: Map<string, Geometry> = new Map<string, Geometry>();
  private _visualData: EngineVisualData;

  constructor(config: Config) {
    this._config = config;
  }

  public clear(): void {
    if (this._mouseController) {
      this._mouseController.destroy();
    }
    this._config.settingsObserver.unsubscribe(this.onSettingsChanged);
  }

  public destroy(): void {
    this.clear();
    this._config.root.removeChild(this._renderer.domElement);
    this._config.scaleObserver.unsubscribe(this.onResize);
    this._config.root.removeChild(this._gui.domElement);
    this._config.root.removeChild(this._stats.dom);
    this._renderer.dispose();
    this._controls.dispose();
    this._scene.clear();
    this._destroyed = true;
  }

  public set onCameraChanged(callback: () => void) {
    this._config.onCameraChanged = callback;
  }

  public setVisualData(engineVisualData: EngineVisualData): void {
    let filterIsUpdated = false;
    if (this._visualData.hiddenIds !== engineVisualData.hiddenIds) {
      this._hiddenInstancesFilter = new DrawingsInstanceSetFilter(engineVisualData.hiddenIds, true);
      filterIsUpdated = true;
    }

    if (this._visualData.filteredIds !== engineVisualData.filteredIds) {
      this._instancesFilter = new DrawingsInstanceSetFilter(engineVisualData.filteredIds);
      filterIsUpdated = true;
    }

    if (!objectUtils.areEqualByFields(this._visualData, engineVisualData, ['instancesIds', 'geometries', 'points'])) {
      this._visualData = engineVisualData;
      this.setGeometriesToRender(engineVisualData);
    } else {
      if (filterIsUpdated) {
        this.onFiltreationUpdated();
      }
      if (engineVisualData.selectedGeometries !== this._visualData.selectedGeometries) {
        this.setSelectedGeometries(engineVisualData.selectedGeometries);
      }
      this._visualData = engineVisualData;
    }
  }

  public async init(visualData: EngineVisualData): Promise<void> {
    this._visualData = visualData;
    const stats = new Stats();
    stats.showPanel(0);
    this._stats = stats;
    const config = this._config;


    this._gui = new GUI({ closeFolders: true });
    this._gui.domElement.style.display = 'none';
    this._gui.domElement.style.position = 'absolute';

    this._materialLookSettings = addMaterialSettings(this._gui, (settings) => {
      this._geometries.forEach((geometry) => {
        geometry.materialLookSettings = settings;
      });
    });

    const scene = new THREE.Scene();
    this._scene = scene;
    const light = new THREE.AmbientLight(0xffffff, THREED_DEFAULTS.lightIntensity);
    addAmbientLightSettings(light, this._gui);
    scene.add(light);
    this._renderer = createRenderer(config.root.clientWidth, config.root.clientHeight);

    this.createCamera();
    config.root.appendChild(this._renderer.domElement);

    this._camera.position.z = 500;

    this.update();
    this._config.root.appendChild(this._gui.domElement);
    this._config.root.appendChild(this._stats.dom);

    this._stats.dom.style.position = 'absolute';
    this._stats.dom.style.display = 'none';

    (window as any).show3dStats = () => {
      this._stats.dom.style.display = 'block';
      this._gui.domElement.style.display = 'block';
    };


    this._config.settingsObserver.subscribe(this.onSettingsChanged);
    this._instancesFilter = new DrawingsInstanceSetFilter(visualData.filteredIds);
    this._hiddenInstancesFilter = new DrawingsInstanceSetFilter(visualData.hiddenIds, true);
    return this.setGeometriesToRender(this._visualData);
  }

  @autobind
  public async createScreenshot(name: string): Promise<void> {
    await delay(1);
    const renderer = createRenderer(1920, 1080);
    const camera = this.replicateCameraWithSize(1920, 1080);
    renderer.render(this._scene, camera);
    const imgData = renderer.domElement.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = imgData;
    link.download = `${name || 'screenshot'}.png`;
    this._config.root.appendChild(this._renderer.domElement);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return Promise.resolve();
  }

  public getProjection(point: ShortPointDescription): THREE.Vector3 {
    const [x, y] = point;
    const vector = new THREE.Vector3(x, -y, 0);
    if (this._camera instanceof THREE.OrthographicCamera) {
      this._camera.updateProjectionMatrix();
    }
    vector.project(this._camera);
    vector.x = Math.round((0.5 + vector.x / 2) * (this._renderer.domElement.clientWidth));
    vector.y = Math.round((0.5 - vector.y / 2) * (this._renderer.domElement.clientHeight));
    return vector;
  }

  public async setGeometriesToRender(
    {
      instancesIds,
      selectedGeometries,
      points,
      geometries,
    }: {
      instancesIds: string[],
      selectedGeometries: string[],
      points: Record<string, ShortPointDescription>,
      geometries: Record<string, DrawingsGeometryInstance>,
    },
  ): Promise<void> {
    this._geometries.forEach(g => g.destroy());
    this._geometries.clear();
    const selectedSet = new Set(selectedGeometries);
    const iterator = AsyncArrayUtils.iteratorWithDelayEveryNStep(instancesIds, 1, 1000);
    for await (const id of iterator) {
      const filterValue = this._instancesFilter.applyFilter(id);
      const hiddenInstancesFilterValue = this._hiddenInstancesFilter.applyFilter(id);
      if (!filterValue || !hiddenInstancesFilterValue) {
        if (this._geometries.has(id)) {
          this._geometries.get(id).destroy();
          this._geometries.delete(id);
        }
        continue;
      }

      this.renderGeometry(id, geometries[id], points, selectedSet.has(id));
    }
  }

  public renderGeometry(
    id: string,
    geometry: DrawingsGeometryInstance,
    points: Record<string, ShortPointDescription>,
    isSelected: boolean,
  ): void {
    try {
      if (this._geometries.has(id)) {
        this._geometries.get(id).setNewGeometry(geometry.geometry as DrawingsGeometryStrokedType);
        return;
      }
      if (DrawingsGeometryUtils.isPolygon(geometry.type, geometry.geometry)
      || DrawingsGeometryUtils.isRectangle(geometry.type, geometry.geometry)) {
        this._geometries.set(id, new Polygon({
          scene: this._scene,
          geometry: geometry.geometry,
          getPointInfo: pointId => points[pointId],
          scaleObserver: this._config.scaleObserver,
          id,
          materialLookSettings: this._materialLookSettings,
        }));
      } else if (DrawingsGeometryUtils.isPolyline(geometry.type, geometry.geometry)) {
        this._geometries.set(id, new Polyline({
          scene: this._scene,
          geometry: geometry.geometry,
          getPointInfo: pointId => points[pointId],
          scaleObserver: this._config.scaleObserver,
          id,
          materialLookSettings: this._materialLookSettings,
          showThicknessContextObserver: this._config.showThicknessContextObserver,
        }));
      }
      if (this._geometries.has(id) && isSelected) {
        this._geometries.get(id).setSelected(true);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async setSelectedGeometries(selectedGeometries: string[]): Promise<void> {
    const selectedGeometriesSet = new Set(selectedGeometries);
    this._geometries.forEach((geometry, key) => {
      geometry.setSelected(selectedGeometriesSet.has(key));
    });
  }

  public setFilteredIds(filteredIds: string[], hiddenIds: string[]): void {
    this._instancesFilter = new DrawingsInstanceSetFilter(filteredIds);
    this._hiddenInstancesFilter = new DrawingsInstanceSetFilter(hiddenIds, true);
    this.onFiltreationUpdated();
  }

  public changeSelection(geometriesToSelect: string[], toUnselect: string[]): void {
    for (const id of toUnselect) {
      const geometry = this._geometries.get(id);
      if (geometry) {
        geometry.setSelected(false);
      }
    }
    for (const id of geometriesToSelect) {
      const geometry = this._geometries.get(id);
      if (geometry) {
        geometry.setSelected(true);
      }
    }
  }

  public removeBackground(): void {
    if (this._background) {
      this._background.destroy();
      this._background = null;
    }
  }

  public setBackground(planeGeometry: HTMLCanvasElement): void {
    if (this._background) {
      this._background.setNewGeometry(planeGeometry);
    } else {
      const {
        drawingOffset,
        drawingOpacity,
        showDrawing,
      } = this._config.settingsObserver.getContext();
      this._background = new PdfPlane({
        scene: this._scene,
        geometry: planeGeometry,
        visible: showDrawing,
        opacity: drawingOpacity,
        z: drawingOffset,
      });
    }
  }

  public home(): void {
    const bgMesh = this._background?.mesh;
    if (!bgMesh) {
      return;
    }
    const length = Math.pow(bgMesh.position.x, 2) + Math.pow(bgMesh.position.y, 2);
    const z = bgMesh.position.z + Math.sqrt(length) * 0.8;
    this._controls.object.position.set(bgMesh.position.x * 2.5, bgMesh.position.y * 2.5, z);
    this._controls.target.set(
      bgMesh.position.x + bgMesh.position.x / 2,
      bgMesh.position.y + bgMesh.position.y / 2,
      0,
    );
  }

  @autobind
  public update(_time?: number): void {
    if (this._destroyed) {
      return;
    }
    this._stats.begin();
    this._controls.update();
    this._renderer.render(this._scene, this._camera);
    if (this._cameraHasBeenChanged) {
      this._cameraHasBeenChanged = false;
      if (this._config.onCameraChanged) {
        this._config.onCameraChanged();
      }
    }
    this._stats.end();
    requestAnimationFrame(this.update);
  }

  @autobind
  public onResize(): void {
    const { offsetHeight: height, offsetWidth: width } = this._config.root;
    if (this._camera instanceof THREE.PerspectiveCamera) {
      this._camera.aspect = width / height;
      this._camera.updateProjectionMatrix();
    } else if (this._camera instanceof THREE.OrthographicCamera) {
      this._camera.left = width / -2;
      this._camera.right = width / 2;
      this._camera.top = height / 2;
      this._camera.bottom = height / -2;
      this._camera.updateProjectionMatrix();
    }
    this._renderer.setSize(width, height);
    this._controls.update();
    this.cameraChanged();
  }

  private onFiltreationUpdated(): void {
    for (const [instanceId, geometry] of this._geometries.entries()) {
      if (
        !this._instancesFilter.applyFilter(instanceId)
        || !this._hiddenInstancesFilter.applyFilter(instanceId)
      ) {
        geometry.destroy();
        this._geometries.delete(instanceId);
      }
    }
    for (const id of this._visualData.instancesIds) {
      if (
        this._instancesFilter.applyFilter(id)
        && this._hiddenInstancesFilter.applyFilter(id)
        && !this._geometries.has(id)
      ) {
        this.renderGeometry(id, this._visualData.geometries[id], this._visualData.points, false);
      }
    }
  }

  private replicateCameraWithSize(width: number, height: number): THREE.Camera {
    let camera: THREE.Camera;

    if (this._camera instanceof THREE.OrthographicCamera) {
      const newCam = this._camera.clone() as THREE.OrthographicCamera;
      newCam.left = width / -2;
      newCam.right = width / 2;
      newCam.top = height / 2;
      newCam.bottom = height / -2;
      newCam.updateProjectionMatrix();
      camera = newCam;
    } else {
      const newCam = this._camera.clone() as THREE.PerspectiveCamera;
      newCam.aspect = width / height;
      newCam.updateProjectionMatrix();
      camera = newCam;
    }
    camera.up.set(0, 0, 1);
    return camera;
  }

  private createCamera(): void {
    const isOrho = this._config.settingsObserver.getContext().isCameraOrthographic;
    const prevPosition = this._camera?.position;
    const prevTarget = this._controls?.target;
    if (this._controls) {
      this._controls.removeEventListener('change', this.onCameraChanged);
      this._controls.dispose();
    }
    if (isOrho) {
      const { clientHeight: height, clientWidth: width } = this._config.root;
      const camera = new THREE.OrthographicCamera(
        width / -2,
        width / 2,
        height / 2,
        height / -2,
        0.1,
        10000,
      );
      camera.up.set(0, 0, 1);
      this._camera = camera;
      const controls = new OrbitControls(this._camera, this._renderer.domElement);
      this._controls = controls;
      this._controls.addEventListener('change', this.cameraChanged);
    } else {
      const { fov, near, far } = this._cameraSettings;
      const camera = new THREE.PerspectiveCamera(
        fov,
        this._config.root.clientWidth / this._config.root.clientHeight,
        near,
        far,
      );
      this._camera = camera;
      this._camera.up.set(0, 0, 1);
      const controls = new OrbitControls(this._camera, this._renderer.domElement);
      this._controls = controls;
      this._controls.addEventListener('change', this.cameraChanged);
    }
    if (prevPosition) {
      this._camera.position.copy(prevPosition);
      this._controls.target.copy(prevTarget);
    } else {
      this.home();
    }
    if (this._mouseController) {
      this._mouseController.destroy();
    }

    this._mouseController = new MouseController({
      camera: this._camera,
      domElement: this._config.root,
      onClick: this._config.onSelect,
      onMouseOver: this._config.onHover,
      scene: this._scene,
      root: this._config.root,
    });
  }

  @autobind
  private cameraChanged(): void {
    this._cameraHasBeenChanged = true;
  }

  @autobind
  private onSettingsChanged(settings: ThreeDSettings): void  {
    const { fillOpacity, isCameraOrthographic: isCameraOrtographic } = this._config.settingsObserver.getPrevContext();
    this._materialLookSettings.opacity = settings.fillOpacity;
    if (settings.fillOpacity !== fillOpacity) {
      this._geometries.forEach((geometry) => {
        geometry.opacity = settings.fillOpacity;
      });
    }

    if (settings.isCameraOrthographic !== isCameraOrtographic) {
      this.createCamera();
    }

    if (this._background) {
      this._background.opacity = settings.drawingOpacity;
      this._background.visible = settings.showDrawing;
      this._background.z = settings.drawingOffset;
    }
  }
}
