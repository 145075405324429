import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import {
  AiRequestQuality,
  DrawingsShortInfo,
  ShortPointDescription,
  WizzardToolsState,
} from 'common/components/drawings/interfaces';
import {
  PdfGeometryResponse,
  PdfGeometryStatus,
} from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DestroyableObject } from '../../../common';
import { FinishedDrawingElement } from '../../../drawings-geometry-entities/temporary';
import { BatchedUpdateCallback, SetCursorHintCallback } from '../../../interfaces';
import { QualityDPI } from './bucket-ai-constants';
import { BucketAIHoverPreview } from './bucket-ai-hover-preview';
import { BucketAIPreview } from './bucket-ai-preview';
import { GeometryType } from './geometry-types';
import { OneClickBase, OneClickBaseConfig, PointsInfo } from './one-click-base';

interface OneClickToolConfig extends OneClickBaseConfig {
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  setCursorMessage: SetCursorHintCallback;
  getCurrentDrawingInfo: () => DrawingsShortInfo;
  oneClickAreaHoverContextObserver: ContextObserver<boolean>;
  onPointClick?: (pointId: string) => void;
}

interface Snapshot extends PointsInfo {
  result?: PdfGeometryResponse;
  window?: ShortPointDescription[];
}

export enum OneClickStates {
  Progress = 1,
  OutOfArea,
  NotFound,
  NoPoints,
  OnlyNegativePoints,
  Expand,
  AddNegative,
}

export class OneClickTool extends DestroyableObject<OneClickToolConfig> {
  protected _hoverPreview: BucketAIHoverPreview;
  protected _destroyed: boolean = false;
  protected _ai: OneClickBase;
  protected _resultGroup: paper.Group;
  protected _snapshots: Snapshot[] = [];
  protected _lastWindow: ShortPointDescription[][];
  protected _preview: BucketAIPreview;
  protected _currentPoint: paper.Point;

  private _restoreMessageExecutor: DeferredExecutor = new DeferredExecutor(3000);
  private _inProgress: boolean = false;
  private _blockAddPoint: boolean = false;

  constructor(config: OneClickToolConfig) {
    super(config);
    this._resultGroup = new paper.Group();
    this._resultGroup.addTo(this._config.layer);
    this.updateMessage(OneClickStates.NoPoints);
    this.init();
  }

  public isValid(): boolean {
    if ((window as any).BUCKET_POLYLINE) {
      return true;
    }
    return this._preview?.validate();
  }

  public clear(): void {
    this._ai.removePoints();
    this._preview?.destroy();
  }

  public destroy(): void {
    this._destroyed = true;
    this._ai.destroy();
    this._preview?.destroy();
    this._hoverPreview.destroy();
  }

  public hasPreview(): boolean {
    return this?._preview?.currentGeomentry?.length > 0;
  }

  public hasGeometies(): boolean {
    return this._ai.hasPoints();
  }

  public getGeometry(): FinishedDrawingElement[] {
    return this._preview.getGeometry();
  }

  public onHover(point: paper.Point): void {
    this._currentPoint = point;
    if (this._snapshots.length) {
      this.showDefaultMessage();
    }
  }

  public removeLastPoint(): [paper.Point, Promise<PdfGeometryResponse>] {
    if (this._ai.hasPoints()) {
      const lastPoint = this._ai.popLastPoint();
      this.removePreview();
      this._ai.cancelSearch();
      this._snapshots.pop();
      const lastSnapshot = this._snapshots[this._snapshots.length - 1];
      return [lastPoint.paperPosition, this.applySnapshot(lastSnapshot)];
    }
    return null;
  }

  public async removePoint(pointId: string): Promise<PdfGeometryResponse> {
    const pointIndex = Number(pointId);
    const snapshot = this._snapshots[this._snapshots.length - 1];
    if (this._preview) {
      this._preview.destroy();
      this._preview = null;
    }
    this._ai.cancelSearch();
    const newSnapshot: Snapshot = {
      points: snapshot.points.filter((_, index) => index !== pointIndex),
      statuses: snapshot.statuses.filter((_, index) => index !== pointIndex),
    };
    const snapshotToAdd = this.findSameSnapshot(newSnapshot) || newSnapshot;
    this._snapshots.push(snapshotToAdd);
    return this.applySnapshot(snapshotToAdd);
  }

  public async addPoint(point: paper.Point, ctrl: boolean): Promise<PdfGeometryResponse> {
    this._restoreMessageExecutor.reset();
    if (this._blockAddPoint) {
      this._blockAddPoint = null;
      return {
        status: PdfGeometryStatus.IGNORE,
        geometries: [],
        response: null,
        hasPoints: !this._ai.arePointsEmpty(),
      };
    }

    this._hoverPreview.clean();

    const { isValid, isPositive } = await this.getPointStatus(point, ctrl);
    this.updateMessage(OneClickStates.Progress);
    if (!isValid) {
      this.updateMessage(OneClickStates.OutOfArea);
      this._restoreMessageExecutor.execute(this.showDefaultMessage);
      return {
        status: PdfGeometryStatus.IGNORE,
        geometries: [],
        response: null,
      };
    }

    this._ai.cancelSearch();

    this._ai.addPoint(point, isPositive);
    const snapshot: Snapshot = this._ai.getPoints();
    this._snapshots.push(snapshot);
    const result = await this.runForCurrentPoints();
    if (result.status === PdfGeometryStatus.Empty) {
      this._snapshots.pop();
      this.updateMessage(OneClickStates.NotFound);
      this._restoreMessageExecutor.execute(this.showDefaultMessage);
      return result;
    } else {
      snapshot.result = result;
      this._lastWindow = result.response.output.windowPolygon;
      this.showDefaultMessage();
    }
    return result;
  }

  protected async getPointStatus(
    _point: paper.Point,
    _ctrl: boolean,
  ): Promise<{ isValid: boolean, isPositive: boolean }> {
    return { isValid: true, isPositive: true };
  }

  protected async runForCurrentPoints(): Promise<PdfGeometryResponse> {
    this._inProgress = true;
    const drawingInfo = this._config.getCurrentDrawingInfo();
    const { dpi, isAuto } = this.getQuality();
    const result = await this._ai.getBucketResults({
      isAuto,
      drawingInfo,
      dpi,
    });
    this._inProgress = false;
    this.showDefaultMessage();
    if (this._destroyed) {
      return;
    }
    if (result.status === PdfGeometryStatus.Empty) {
      this._ai.popLastPoint();
      return result;
    }
    this._snapshots[this._snapshots.length - 1].result = result;
    this._lastWindow = result.response.output.windowPolygon;
    this.renderPreview(result);
    return result;
  }

  protected removePreview(): void {
    if (this._preview) {
      this._preview.destroy();
      this._preview = null;
    }
  }

  protected updateMessage(_state: OneClickStates): void {
    ConstantFunctions.doNothing();
  }

  protected checkExtensionState?(): OneClickStates;

  protected getQuality(): { dpi: number, isAuto: boolean } {
    const { quality = AiRequestQuality.Auto } = this._config.wizzardSettingsObserver.getContext();
    return { dpi: QualityDPI[quality], isAuto: quality === AiRequestQuality.Auto };
  }

  protected init(): void {
    this._hoverPreview = new BucketAIHoverPreview({ ...this._config, type: GeometryType.Polygon });
  }

  @autobind
  protected async onRemovePoint(pointId: string, e: PaperMouseEvent): Promise<void> {
    this._blockAddPoint = true;
    ConstantFunctions.stopEvent(e);
    this._config.onPointClick(pointId);
  }

  private async applySnapshot(snapshot: Snapshot): Promise<PdfGeometryResponse> {
    this._ai.removePoints();
    if (!snapshot) {
      this._lastWindow = null;
      return Promise.resolve(null);
    }
    this._ai.applyPoints(snapshot);
    if (!snapshot.points.length || !snapshot.statuses.some(x => x.positive)) {
      this._lastWindow = null;
      if (this._preview) {
        this._preview.destroy();
        this._preview = null;
      }
      return {
        status: PdfGeometryStatus.Empty,
        geometries: [],
        response: null,
        hasPoints: snapshot.points.length > 0,
      };
    }

    if (snapshot.result) {
      this.renderPreview(snapshot.result);
      this._lastWindow = snapshot.result.response.output.windowPolygon;
      return snapshot.result;
    } else {
      const withResult = this._snapshots.reduce((acc, v) => v.result ? v : acc, null);
      if (withResult) {
        this._lastWindow = withResult.result.response.output.windowPolygon;
      }
      return this.runForCurrentPoints();
    }
  }

  private renderPreview(result: PdfGeometryResponse): void {
    if (!this._preview) {
      this._preview = new BucketAIPreview({
        layer: this._resultGroup,
        newDrawingStylesObserver: this._config.newDrawingStylesObserver,
        renderParamsContextObserver: this._config.renderParamsContextObserver,
        colorCache: this._config.colorCache,
        closed: this._config.type === GeometryType.Polygon,
      });
    }

    this._preview.setGeometry({
      contours: result.geometries.map((x) => [x.points, ...(x.holes || [])]),
    });
  }

  private findSameSnapshot(snapshot: Snapshot): Snapshot {
    return this._snapshots.find((x) => this.areSnapshotsEqual(x, snapshot));
  }

  private areSnapshotsEqual(snapshot1: Snapshot, snapshot2: Snapshot): boolean {
    if (snapshot1.points.length !== snapshot2.points.length) {
      return false;
    }
    for (let i = 0; i < snapshot1.points.length; i++) {
      if (
        snapshot1.points[i][0] !== snapshot2.points[i][0]
        || snapshot1.points[i][1] !== snapshot2.points[i][1]
        || snapshot1.statuses[i].positive !== snapshot2.statuses[i].positive
      ) {
        return false;
      }
    }
    return true;
  }

  @autobind
  private showDefaultMessage(): void {
    if (this._inProgress || this._restoreMessageExecutor.isWaitingForExecution()) {
      return;
    }
    if (this._ai.arePointsEmpty()) {
      this.updateMessage(OneClickStates.NoPoints);
      return;
    }

    if (this._ai.allPointsNegative()) {
      this.updateMessage(OneClickStates.OnlyNegativePoints);
      return;
    }


    if (this.checkExtensionState) {
      const state = this.checkExtensionState();
      if (state) {
        this.updateMessage(state);
      }
    } else {
      this._config.setCursorMessage(null);
    }
  }
}
