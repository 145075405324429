import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as paper from 'paper';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './drawings-canvas.scss';

import { TwoDActions } from '2d/actions/creators';
import { AssignedPia } from '2d/index';
import { RenderIf } from 'common/components/render-if';
import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { DrawingsTextSearchActions, WizzardToolsActions } from '../actions/creators';
import { DrawingsAnnotationActions } from '../actions/creators/annotation';
import { CalibrateActions } from '../actions/creators/calibrate';
import { DrawingsActions } from '../actions/creators/common';
import { MagicSearchActions } from '../actions/creators/magic-search';
import { PdfFilterActions } from '../actions/creators/pdf-filter';
import { DrawingsUserAnnotationActions } from '../actions/creators/user-annotation';
import {
  DrawingsBatchUpdateGeometries,
  DrawingsGeometryParameters,
  DrawingsUpdateAnnotationGeometry,
} from '../actions/payloads/annotation';
import { DrawingsAnnotationsChangeColors, DrawingsAnnotationsPosition } from '../actions/payloads/user-annotation';
import { DrawingsCanvasConstants } from '../constants/drawing-canvas-constants';
import { DrawingDialogs } from '../constants/drawing-dialogs';
import { NoScaleDescriptionType, NoScaleDialogState, NotScaledDialog } from '../dialogs';
import { Drawings3DView } from '../drawings-3d-view';
import { DrawingsCanvasContextProvider } from '../drawings-canvas-context';
import { DrawingsCompleteContextMenu } from '../drawings-complete-context-menu';
import { DrawingContextObserver, DrawingContextObserverWithPrev } from '../drawings-contexts';
import {
  AiSuggestSettings,
  DrawingRendererEngine,
  DrawingsCursorTypeHelper,
  DrawingsGeometryDragEventHelper,
  EditPermissions,
  FinishDrawConfig,
  FinishDrawApi,
} from '../drawings-geometry';
import {
  DrawingsGeometryConversionProcessor,
  DrawingsOrthogonalModeStatusController,
  DrawingsUserAnnotationEventProcessor,
} from '../drawings-geometry/drawings-helpers';
import { DrawingsViewHelper, EventConfiguration } from '../drawings-geometry/drawings-helpers/drawings-view-helper';
import { PaperMouseEventUtils } from '../drawings-geometry/drawings-helpers/paper-mouse-event-utils';
import { SelectionEventsHelper } from '../drawings-geometry/drawings-helpers/visual-helpers/selection-events-helper';
import { HoverInstanceChangeEvent, NewDrawingSettings } from '../drawings-geometry/interfaces';
import { TextSearch } from '../drawings-geometry/text-search-results';
import {
  DrawingsInstanceType,
} from '../enums';
import { DrawingGeometryOperationType } from '../enums/drawing-geometry-operation-type';
import {
  ClosedContoursDrawModes,
  DrawingsDrawMode,
  DrawingsModesWithCrosshair,
  DrawingsModesWithEnabledDelete,
  MagicSearchDrawModes,
} from '../enums/drawings-draw-mode';
import {
  WizzarsStatusToDropperDrawMode,
  WizzardStatus,
  WizzardStatusToFinderDrawMode,
} from '../enums/dropper-state';
import { DrawingSelectMeasurementsListeners } from '../helpers';
import { DrawingsKeyboardListenersHelper } from '../helpers/drawings-keyboard-listeners-helper';
import { DrawingsSpacebarHeldHelper } from '../helpers/drawings-spacebar-held-helper';
import { DrawingsViewportHelper } from '../helpers/viewport/viewport';
import {
  DrawingContextMenuPosition,
  HoverState,
  InfoWindowPosition,
  SaveDrawModeOptions,
  DrawingsTextSearchState,
  DrawingsUserAnnotationsState,
  WizzardToolsState,
  FixContour,
  ContourToFixType,
} from '../interfaces';

import { DrawingSnapping } from '../interfaces/drawing';
import {
  DrawingsPointInfo,
  DrawingsProcessedAiAnnotation,
  ShortPointDescription,
} from '../interfaces/drawing-ai-annotation';
import { MeasuresViewSettings } from '../interfaces/drawing-text-render-parameters';
import { DrawingsCanvasRect } from '../interfaces/drawings-canvas-rect';
import {
  DrawingsBounds,
  DrawingsCalibrationLineGeometry,
  DrawingsGeometryType,
} from '../interfaces/drawings-geometry';
import { DrawingsGeometryGroup } from '../interfaces/drawings-geometry-group';
import { DrawingsGeometryInstance, DrawingsGeometryInstanceWithId } from '../interfaces/drawings-geometry-instance';
import { DrawingsGeometryRendererAPI } from '../interfaces/drawings-geometry-renderer-api';
import {
  DrawingsGeometryStyle,
} from '../interfaces/drawings-geometry-style';
import { DrawingsInstanceMeasure } from '../interfaces/drawings-instance-measure';
import { DrawingsGroupMeasure } from '../interfaces/drawings-measures';
import { DrawingsShortInfo } from '../interfaces/drawings-short-drawing-info';
import {
  DrawingRulerPoints,
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
  DrawingUserAnnotationSticker,
} from '../interfaces/drawings-user-annotation';
import { CursorHintType } from '../layout-components/drawings-cursor-hint';
import {
  DrawingsStickerLayoutApi,
  DrawingsUserAnnotationLegend,
  DrawingsUserAnnotationsLegendApi,
  CommentsContentLayout,
} from '../user-annotations-components';
import { InstancesUtils } from '../utils';
import { DrawingAnnotationUtils } from '../utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from '../utils/drawings-canvas-utils';
import { DrawingsCommonUtils } from '../utils/drawings-common-utils';
import { DrawingsPaperUtils } from '../utils/drawings-paper-utils';
import { DrawingsUndoRedoHelper } from '../utils/drawings-undo-redo-helper';
import { DrawingsDragLayoutCanvasWrapper } from './drag-layout-canvas-wrapper';
import { DrawingsContextsMenus } from './drawings-contexts-menus';
import { DrawingsOperationMenuWrapper } from './drawings-operation-menus-wrapper';
import { DrawingsCanvasContextWrapperProps, withDrawingsCanvasContextWrapper } from './wrap-drawings-canvas-context';

export interface DrawingLayoutApi {
  setCanvasSize: (width: number, height: number) => void;
  getDrawingCanvas: () => HTMLCanvasElement;
  setViewportZoom: (zoom: number) => void;
  updateCanvasRenderParameters: (rect: DrawingsCanvasRect) => void;
  // todo: https://kreosoftware.atlassian.net/browse/KREOP-11435
  getInstanceMeasures: (
    id: string,
    geometry: Record<string, DrawingsGeometryInstance<DrawingsGeometryType>>,
  ) => DrawingsInstanceMeasure;
  rotate: (rotation: number) => void;
  changeEntitiesColor: (ids: string[], color: string) => void;
  getAnnotationsBounds: (ids: string[]) => DrawingsBounds;
  clearAnnotationsLayout: () => void;
  updateViewportParams: () => void;
  focusTextSearchResults: (drawingId: string, id: number) => void;
}


interface DispatchProps {
  updateGeometry: (updates: DrawingsUpdateAnnotationGeometry) => void;
  updateInstanceStyleParameter: <T extends keyof DrawingsGeometryParameters>(
    instancesId: string[],
    parameter: T,
    value: DrawingsGeometryParameters[T],
  ) => void;
  selectAnnotations: (ids: string[]) => void;
  addImages: (images: DrawingUserAnnotationImage[], pageId: string) => void;
  addRulers: (rulers: DrawingUserAnnotationRuler[], pageId: string) => void;
  addStickers: (stickers: DrawingUserAnnotationSticker[], pageId: string) => void;
  removeAnnotations: (ids: string[], pageId: string) => void;
  onAnnotationColorChanged: (colorChanges: DrawingsAnnotationsChangeColors[]) => void;
  updateImagesPositions: (updates: DrawingsAnnotationsPosition[]) => void;
  updateImageParameter: (id: string, value: number, parameter: keyof DrawingUserAnnotationImage) => void;
  updateRulerPositions: (id: string, points: DrawingRulerPoints) => void;
  updateStickersPosition: (updatedPositions: DrawingsAnnotationsPosition[]) => void;
  addLegend: (point: ShortPointDescription) => void;
  setContextMenuPosition: (position: DrawingContextMenuPosition) => void;
  setTempStickerPosition: (position: ShortPointDescription) => void;
  saveHoverState: (hoverState: HoverState) => void;
  removeHoverState: (insanceId: string) => void;
  setWizzardState: (dropperState: WizzardStatus, itemsCount?: number) => void;
  setWizzardWorkingArea: (area: ShortPointDescription[]) => void;
  setWizzardSelectionArea: (area: ShortPointDescription[]) => void;
  addFinderSelectedGeometryToRemove: (geometryId: number) => void;
  clearWizzardResult: () => void;
  setCursorHintType: (hintType: CursorHintType) => void;
  runDropper: (point: ShortPointDescription) => void;
  onCalibrateLineDrawn: (line: DrawingsCalibrationLineGeometry) => void;
  afterCopy: (ids: string[]) => void;
  setMagicSearchZone: (zone: ShortPointDescription[]) => void;
  setContoursToMagicSearch: (
    countours: ShortPointDescription[][],
    drawMode: DrawingsDrawMode,
  ) => void;
  toggleMagicSearchPreviewStatus: (id: number[]) => void;
  openNotCalibratedDialog: (dialogData: NoScaleDialogState) => void;
  onSelectTextForSearch: (query: string) => void;
  sendFinishGeometryApi: (api: FinishDrawApi) => void;
  sendAiSearchFixApi: (api: FixContour) => void;
  applyMagicSearchFix: (sourceIndices: number[], polygons: ShortPointDescription[][]) => void;
  updateMagicSearchFixStatus: (status: RequestStatus) => void;
  addToPdfFilter: (fileId: string, pageId: string, area: ShortPointDescription[]) => void;
}

interface ItemToHighlight {
  id: number;
  drawingId: string;
}


interface StateProps {
  isImperial: boolean;
  fileData: DrawingsProcessedAiAnnotation;
  hiddenIds: string[];
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  pointsInfo: Record<string, DrawingsPointInfo>;
  drawingsInfo: Record<string, DrawingsShortInfo>;
  filteredElementIds: string[];
  userAnnotationState: DrawingsUserAnnotationsState;
  selectedAnnotations: string[];
  selectedGroups: string[];
  rotation: number;
  textSearch: DrawingsTextSearchState;
  tempSticker: { position: ShortPointDescription, hasText: boolean };
  wizzardToolsSettings: WizzardToolsState;
  is3d: boolean;
  pia: Record<string, AssignedPia>;
  magicSearchSpecifyZone: boolean;
}

interface OwnProps extends DrawingsCanvasContextWrapperProps {
  canToggleFullScreen: boolean;
  disableDrawMouseMove: boolean;
  marqueeZoomEnabled: boolean;
  groups: DrawingsGeometryGroup[];
  canEditUserAnnotations: boolean;
  canUseAiSuggestForLines: boolean;
  viewportHelper: DrawingsViewportHelper;
  scale: number;
  paperSize: string;
  metersPerPixel: number;
  drawMode: DrawingsDrawMode;
  currentDrawingId: string;
  instances: Record<string, DrawingsGeometryInstanceWithId>;
  selectedInstances: string[];
  snappingObserver: DrawingContextObserver<DrawingSnapping>;
  zoomObserver: DrawingContextObserverWithPrev<{ zoom: number }>;
  drawingRendered: boolean;
  points: Record<string, ShortPointDescription>;
  colors: Record<string, string>;
  layoutFocused: boolean;
  showFlyingMenu: boolean;
  isFullScreen: boolean;
  getActuralInstancesMeasures: (instancesIds: string[]) => DrawingsInstanceMeasure[];
  selectDrawingGroups: (instancesIds: string[]) => void;
  onCanvasFocus: () => void;
  isContainsInGroups: (instancesIds: string[]) => boolean;
  sendApi: (api: DrawingLayoutApi) => void;
  onFinishEditAnnotation: (
    pointsUpdated: Record<string, ShortPointDescription>,
    measurers: DrawingsInstanceMeasure[],
  ) => void;
  setDrawMode: (mode: DrawingsDrawMode, settings?: SaveDrawModeOptions) => void;
  onInstancesMeasuresUpdated?: (measures: DrawingsInstanceMeasure[]) => void;
  onFocus: (ids: string[], ignoreSetting?: boolean) => void;
  toggleElementsPanel: () => void;
  toggleFullScreen: () => void;
  setSelectGeometryGroup: (groupIds: string[]) => void;
  canEditMeasurement: boolean;
  canViewMeasurement: boolean;
  canEditReport: boolean;
  canViewReport: boolean;
  syncGroupSelection: (id: string) => void;
  cacheGroupMeasure: (groupMeasures: Record<string, DrawingsGroupMeasure>) => void;
  calculateCurrentPageGroupMeasures: (groupId: string, instances: string[]) => DrawingsGroupMeasure;

}

interface Props extends StateProps, OwnProps, DispatchProps, AnalyticsProps { }

interface ComponentState {
  drawingCanvasRef: HTMLCanvasElement;
  completeDrawMenuPosition: ShortPointDescription;
  completeDrawMenuConfig: FinishDrawConfig;
  completeDrawMenuApi: FinishDrawApi;
  isSpaceBarHeld: boolean;
  showAnnotationFlyingMenu: boolean;
}


class CanvasLayoutComponent extends React.PureComponent<Props, ComponentState> {
  private scrollLayoutRef: HTMLDivElement = null;
  private mainRef: HTMLDivElement = null;
  private stickerLayoutApi: DrawingsStickerLayoutApi;
  private legendApi: DrawingsUserAnnotationsLegendApi;

  private searchItemToHighlight: ItemToHighlight;

  private drawingTextParamsObserver: DrawingContextObserver<MeasuresViewSettings>;
  private editPermissionsObserver: DrawingContextObserver<EditPermissions>;
  private sharedSpaceBarHeldContext: DrawingContextObserver<boolean> = new DrawingContextObserver<boolean>(false);
  private measurementsAutocompleteContext = new DrawingContextObserver<AiSuggestSettings>({
    enabled: this.props.isAutoCompleteEnabled,
    canUseAiSuggestForLines: this.props.canUseAiSuggestForLines,
  });
  private newDrawingStylesObserver = new DrawingContextObserver<NewDrawingSettings>(this.props.newInstancesSettings);
  private wizzardSettingsObserver = new DrawingContextObserver<WizzardToolsState>(this.props.wizzardToolsSettings);
  private engine: DrawingRendererEngine;
  private textSearch: TextSearch;

  private dragGeometryEventsHelper: DrawingsGeometryDragEventHelper
    = new DrawingsGeometryDragEventHelper(this.sharedSpaceBarHeldContext);
  private drawingViewHelper: DrawingsViewHelper = new DrawingsViewHelper({
    eventDefaults: {
      onLeftMouseClick: this.onLayoutLeftMouseButtonClick,
      onRightMouseClick: this.onLayoutRightMouseButtonClick,
      onMouseDown: this.onMouseDown,
      onMouseMove: this.onMouseMove,
      onMouseUp: this.onMouseUp,
      onDoubleClick: ConstantFunctions.doNothing,
    },
    sharedSpaceBarHeldContext: this.sharedSpaceBarHeldContext,
    scope: paper,
    zoomObserver: this.props.zoomObserver,
  });
  private selectionEventHelper = new SelectionEventsHelper();
  private cursorTypeHelper: DrawingsCursorTypeHelper;
  private keyboardListener: DrawingsKeyboardListenersHelper = new DrawingsKeyboardListenersHelper({
    removeKeyDownEventListener: this.props.removeKeyDownEventListener,
    addKeyDownEventListener: this.props.addKeyDownEventListener,
    removeKeyUpEventListener: this.props.removeKeyUpEventListener,
    addKeyUpEventListener: this.props.addKeyUpEventListener,
    toggleAiSuggest: this.props.toggleAutocomplete,
    onShowAll: this.showAll,
    show: this.props.showSelectedInstances,
    hide: this.props.hideSelectedInstances,
    focus: this.focus,
    isolate: this.props.isolateSelectedInstances,
    selectAll: this.selectAll,
    isSelectedInstancesHidden: this.isSelectedInstancesHidden,
    toggleElementsPanel: this.props.toggleElementsPanel,
    unselect: this.unselect,
    toggleSnapping: () => this.props.toggleSnapping(),
  });
  private onlySelectKeyboardListener = new DrawingSelectMeasurementsListeners({
    removeKeyDownEventListener: this.props.removeKeyDownEventListener,
    addKeyDownEventListener: this.props.addKeyDownEventListener,
    removeKeyUpEventListener: this.props.removeKeyUpEventListener,
    addKeyUpEventListener: this.props.addKeyUpEventListener,
    copyCurrentSelectedInstances: this.copyCurrentSelectedInstances,
    cut: this.cut,
    pasteInstances: this.pasteInstances,
    removeDrawingsFromGroups: this.props.removeSelectedElementsFromGroups,
    groupDrawings: this.props.groupMeasurementsWithUndoRedo,
    onRemoveInstancesHandler: this.onRemoveInstancesHandler,
    subtract: this.props.startSubtract,
    unite: this.props.startUnite,
    duplicate: this.props.duplicateSelectedInstances,
    pasteWithPoint: this.pasteWithPoint,
    focus: this.props.focus,
  });

  private userAnnotationEventProcessor: DrawingsUserAnnotationEventProcessor =
    new DrawingsUserAnnotationEventProcessor({
      addUndoRedo: this.props.addUndoRedo,
      getEngine: () => this.engine,
      getCurrentDrawingId: () => this.props.currentDrawingId,
      updateImageParameter: this.props.updateImageParameter,
      updateRulerPositions: this.props.updateRulerPositions,
      updateImagesPositions: this.props.updateImagesPositions,
      updateStickersPositions: this.props.updateStickersPosition,
      selectAnnotation: this.selectAnnotation,
      addImages: this.addImages,
      addRulers: this.addRulers,
      removeAnnotations: this.props.removeAnnotations,
      getStickerLayoutApi: () => this.stickerLayoutApi,
      getAnnotationState: () => this.props.userAnnotationState,
    });
  private geometryConversionProcessor: DrawingsGeometryConversionProcessor =
    new DrawingsGeometryConversionProcessor({
      addUndoRedo: this.props.addUndoRedo,
      getEngine: () => this.engine,
      getInstanceById: instanceId => this.props.instances[instanceId],
      getInstanceMeasures: instanceId => this.props.elementMeasurement[instanceId],
      onUpdateInstances: this.props.updateGeometry,
      onUpdateMeasures: this.onInstancesMeasuresUpdate,
      changeDrawMode: this.props.setDrawMode,
    });
  private spaceBarListener: DrawingsSpacebarHeldHelper = new DrawingsSpacebarHeldHelper({
    setSpaceBarHeld: this.setSpaceBarHeld,
  });
  private threeDParamsAbilityObserver: DrawingContextObserver<boolean>;
  private orthogonalStatusController: DrawingsOrthogonalModeStatusController =
    new DrawingsOrthogonalModeStatusController(this.props.isOrthogonalModeEnabled);

  constructor(props: Props) {
    super(props);
    this.state = {
      drawingCanvasRef: null,
      completeDrawMenuPosition: null,
      completeDrawMenuApi: null,
      completeDrawMenuConfig: null,
      isSpaceBarHeld: false,
      showAnnotationFlyingMenu: false,
    };
    this.drawingTextParamsObserver = new DrawingContextObserver(this.extractTextRenderParamsFromProps(props));
    this.threeDParamsAbilityObserver = new DrawingContextObserver<boolean>(props.canEdit3dMeasurements);
    this.cursorTypeHelper = new DrawingsCursorTypeHelper(this.drawingViewHelper);
    this.editPermissionsObserver = new DrawingContextObserver({
      canEditUserAnnotation: this.props.canEditUserAnnotations,
      canEditMeasure: this.props.canEditMeasurement,
    });
  }

  public render(): React.ReactNode {
    const {
      scale,
      metersPerPixel,
      drawMode,
      fileData,
      drawingRendered,
      userAnnotationState,
    } = this.props;
    return (
      <DrawingsCanvasContextProvider
        getDrawingWrapperRef={this.drawingViewHelper.getDrawingsWrapperRef}
        getDefaultCanvasRef={this.getDefaultCanvasRef}
        scale={scale}
        metersPerPx={metersPerPixel}
        getScrollLayoutRef={this.getScrollLayoutRef}
        instanceSelect={this.onInstanceSelect}
        getMainLayoutRef={this.getMainLayoutRef}
      >
        <DrawingsContextsMenus
          canToggleFullScreen={this.props.canToggleFullScreen}
          isRendererInitialized={this.hasSelectedDrawing(this.props, this.state)}
          startContinue={this.startContinue}
          canEditAnnotations={this.props.canEditUserAnnotations}
          removeAnnotations={this.removeAnnotationsWithUndo}
          selectedAnnotations={this.props.selectedAnnotations}
          selectAll={this.selectAll}
          focus={this.focus}
          onShowAll={this.showAll}
          isFullScreen={this.props.isFullScreen}
          showFlyingMenu={this.props.showFlyingMenu}
          sendFlyingMenuRef={this.props.viewportHelper.saveMenuRef}
          canViewReport={this.props.canViewReport}
          cut={this.cut}
          onRemoveInstancesHandler={this.removeSelectedInstances}
          canPasteToSamePlace={this.canPasteToSamePlace}
          pasteInSamePlace={this.pasteToSamePlace}
          canPaste={this.props.copyPasteBuffer.canPaste}
          copy={this.copyCurrentSelectedInstances}
          paste={this.pasteInstances}
          canEditReport={this.props.canEditReport}
          canEditMeasurement={this.props.canEditMeasurement}
          canViewMeasurement={this.props.canViewMeasurement}
          toggleFullScreen={this.props.toggleFullScreen}
          groupDrawings={this.props.groupMeasurementsWithUndoRedo}
          onContextMenuClose={this.onContextMenuClose}
          showAnnotations={this.state.showAnnotationFlyingMenu}
          pasteWithPoint={this.pasteWithPoint}
        />
        {
          this.state.completeDrawMenuPosition ? (
            <DrawingsCompleteContextMenu
              onClose={this.onCloseFinishEditMenu}
              api={this.state.completeDrawMenuApi}
              config={this.state.completeDrawMenuConfig}
              position={this.state.completeDrawMenuPosition}
            />
          ) : null
        }
        <DrawingsOperationMenuWrapper
          sendMenuRef={this.props.viewportHelper.saveMenuRef}
        />
        <DrawingsDragLayoutCanvasWrapper
          shouldIgnoreSettings={false}
          onDragFinish={this.onDragFinish}
          onDragStart={this.onDragStart}
          isLeftMouseButtonDragEnabled={this.state.isSpaceBarHeld}
          setScrollLayoutRef={this.saveScrollLayoutRef}
          onScroll={this.props.viewportHelper.onChangeViewport}
          onCanvasResized={this.props.viewportHelper.onResize}
          canViewMeasurement={this.props.canViewMeasurement}
          setMainLayoutRef={this.saveMainLayoutRef}
          setDrawingsWrapperRef={this.drawingViewHelper.setDrawingsWrapperRef}
          viewportHelper={this.props.viewportHelper}
        >
          <RenderIf
            condition={!DrawingsCommonUtils.isDrawEnabled(drawMode) && userAnnotationState.legend && drawingRendered}
          >
            <DrawingsUserAnnotationLegend
              canEditAnnotations={this.props.canEditUserAnnotations}
              viewportHelper={this.props.viewportHelper}
              fileData={fileData}
              getTruePosition={this.getPositionOnLayer}
              sendLegendApi={this.saveLegendApi}
              getGroupMeasures={this.props.calculateCurrentPageGroupMeasures}
              zoomHandler={this.props.zoomObserver}
            />
          </RenderIf>
          <CommentsContentLayout
            zoomObserver={this.props.zoomObserver}
            shouldBlockEvents={this.state.isSpaceBarHeld}
            canEditUserAnnotations={this.props.canEditUserAnnotations}
            drawMode={this.props.drawMode}
            rotation={this.props.rotation}
            sendApi={this.saveStickersLayoutApi}
            getTruePosition={this.getPositionOnLayer}
            removeStickers={this.props.removeAnnotations}
            tempPosition={this.props.tempSticker?.position}
            removeTempSticker={this.removeTempSticker}
            dragEventsHelper={this.dragGeometryEventsHelper}
            drawingId={this.props.currentDrawingId}
            getCanvasCoordinates={this.getCanvasPosition}
            onChangeAnnotationSelection={this.selectAnnotation}
          />
          <canvas
            /* canvas игнорирует события onFocus и onBlur даже с добавлением tabIndex */
            onClick={this.props.onCanvasFocus}
            ref={this.saveDrawingCanvasRef}
            className={
              classNames(
                'drawings-canvas__drawing',
                {
                  'drawings-canvas__drawing--draw': this.showAim(),
                },
              )
            }
          />
        </DrawingsDragLayoutCanvasWrapper>
        {this.props.is3d && this.props.currentDrawingId ? (
          <Drawings3DView
            instancesIds={this.props.fileData?.instances}
            viewportHelper={this.props.viewportHelper}
            scale={this.props.scale}
            metersPerPixel={this.props.metersPerPixel}
            onSelect={this.onInstanceSelect}
            onHover={this.onHoverInstanceChanged}
            onToggleFullScreen={this.props.toggleFullScreen}
            onFocus={this.props.onCanvasFocus}
            canToggleFullScreen={this.props.canToggleFullScreen}
          />
        ) : null}
        <NotScaledDialog />
      </DrawingsCanvasContextProvider>
    );
  }

  public componentDidUpdate(prevProps: Props, prevState: ComponentState): void {
    const newState: Partial<ComponentState> = {};
    let shouldUpdateState = false;

    const drawMode = this.getDrawModeFromProps(this.props);
    const prevDrawMode = this.getDrawModeFromProps(prevProps);

    if (this.props.canEdit3dMeasurements !== prevProps.canEdit3dMeasurements) {
      this.threeDParamsAbilityObserver.updateContext(this.props.canEdit3dMeasurements);
    }

    this.drawingViewHelper.disableListeners = !this.props.drawingRendered;
    if (this.props.selectedInstances !== prevProps.selectedInstances) {
      this.changeElementsSelection(this.props.selectedInstances);
    }

    if (this.props.wizzardToolsSettings !== prevProps.wizzardToolsSettings) {
      this.wizzardSettingsObserver.updateContext(this.props.wizzardToolsSettings);
    }

    this.cursorTypeHelper.marqueZoom = this.props.marqueeZoomEnabled;

    this.orthogonalStatusController.settingsEnabled = this.props.isOrthogonalModeEnabled;

    if (this.props.selectedAnnotations !== prevProps.selectedAnnotations && this.engine) {
      let showAnnotationFlyingMenu = false;
      this.engine.changeAnnotationSelection(this.props.selectedAnnotations);
      if (this.props.selectedAnnotations.length) {
        showAnnotationFlyingMenu = !this.props.selectedAnnotations.some(
          x => x in this.props.userAnnotationState.stickers,
        );
      }
      if (this.state.showAnnotationFlyingMenu !== showAnnotationFlyingMenu) {
        shouldUpdateState = true;
        newState.showAnnotationFlyingMenu = showAnnotationFlyingMenu;
      }
    }

    this.renderIfNeeded(this.props, prevProps);

    if (
      this.props.isAutoCompleteEnabled !== prevProps.isAutoCompleteEnabled
      || this.props.canUseAiSuggestForLines !== prevProps.canUseAiSuggestForLines
    ) {
      this.measurementsAutocompleteContext.updateContext({
        enabled: this.props.isAutoCompleteEnabled,
        canUseAiSuggestForLines: this.props.canUseAiSuggestForLines,
      });
    }

    if (this.props.isKeepOriginName !== prevProps.isKeepOriginName && this.engine) {
      this.engine.keepOriginNames = this.props.isKeepOriginName;
    }

    this.updateEngineParameters(this.props, prevProps);
    if (this.props.layoutFocused !== prevProps.layoutFocused) {
      if (this.props.layoutFocused) {
        this.addEventListeners();
      } else {
        this.removeEventListeners();
      }
    }
    if (this.props.hiddenIds !== prevProps.hiddenIds) {
      if (this.props.rendererApi) {
        this.props.rendererApi.engine.setVisibility(prevProps.hiddenIds, true);
        this.props.rendererApi.engine.setVisibility(this.props.hiddenIds, false);
      }
      if (
        this.props.drawMode === DrawingsDrawMode.Modify
        && this.props.hiddenIds.includes(this.props.selectedInstances[0])
      ) {
        this.props.setDrawMode(DrawingsDrawMode.Disabled);
      }
    }
    if (this.props.drawMode !== DrawingsDrawMode.Sticker && this.props.tempSticker) {
      this.removeTempSticker();
      shouldUpdateState = true;
    }

    const hasSelectedDrawing = this.props.currentDrawingId && this.props.drawingRendered && this.state.drawingCanvasRef;
    const prevHasSelectedDrawing =
      prevProps.currentDrawingId && prevProps.drawingRendered && prevState.drawingCanvasRef;

    const drawModeChanged = drawMode !== prevDrawMode;
    if (drawModeChanged) {
      if (drawMode !== DrawingsDrawMode.Disabled) {
        this.props.setContextMenuPosition(null);
      }
      this.cursorTypeHelper.drawModeEnabled = DrawingsModesWithCrosshair.includes(drawMode);
    }
    if (
      drawModeChanged
      || hasSelectedDrawing && !prevHasSelectedDrawing
    ) {
      if (DrawingsCommonUtils.isDrawEnabled(drawMode)) {
        this.onlySelectKeyboardListener.removeEventListeners(this.props.canEditMeasurement);
      } else {
        const eventsForRestore = ['onRightMouseClick', 'onMouseMove', 'onMouseUp', 'onMouseDown'];
        if (drawMode !== DrawingsDrawMode.Modify) {
          eventsForRestore.push('onLeftMouseClick');
        }
        this.drawingViewHelper.restoreDefaultEvents(eventsForRestore as Array<keyof EventConfiguration>);
        this.onlySelectKeyboardListener.addEventListeners(this.props.canEditMeasurement);
      }
      if (this.props.rendererApi) {
        this.dragGeometryEventsHelper.drawMode = drawMode;
        this.engine.changeDrawMode(drawMode);
      }
    }

    if (this.props.newInstancesSettings !== prevProps.newInstancesSettings) {
      this.newDrawingStylesObserver.updateContext(this.props.newInstancesSettings);
    }

    if (this.textSearch) {
      this.textSearch.setResults(this.props.currentDrawingId, this.props.textSearch.results.results);
      if (this.props.currentDrawingId === this.props.textSearch.textRectangles?.drawingId) {
        this.textSearch.setRects(this.props.textSearch.textRectangles);
      }
    }

    if (this.props.disableDrawMouseMove !== prevProps.disableDrawMouseMove && this.engine) {
      this.engine.mouseMoveDisabled = this.props.disableDrawMouseMove;
    }

    this.updateScaleIfNeeded(prevProps);
    this.initEngineIfNeeded(prevProps, prevState);
    if (shouldUpdateState) {
      this.setState(newState as ComponentState);
    }
  }

  public componentDidMount(): void {
    document.addEventListener('touchend', this.forceStopDrag);
    this.props.sendApi({
      setCanvasSize: this.drawingViewHelper.setCanvasSize,
      setViewportZoom: this.drawingViewHelper.setViewportZoom,
      updateCanvasRenderParameters: (rect) => {
        this.drawingViewHelper.updateCanvasRenderParameters(rect);
        this.stickerLayoutApi.updateViewportParams();
        if (this.legendApi) {
          this.legendApi.updateViewportParams();
        }
      },
      updateViewportParams: () => {
        this.stickerLayoutApi.updateViewportParams();
        if (this.legendApi) {
          this.legendApi.updateViewportParams();
        }
      },
      getDrawingCanvas: this.getDrawingCanvas,
      getInstanceMeasures: this.getInstanceMeasures,
      rotate: this.drawingViewHelper.rotate,
      changeEntitiesColor: this.changeEntitiesColor,
      getAnnotationsBounds: this.getAnnotationBounds,
      clearAnnotationsLayout: this.clearAnnotations,
      focusTextSearchResults: (drawingId, id) => {
        if (this.textSearch && this.props.currentDrawingId === drawingId) {
          this.textSearch.setHighlightItem(drawingId, id);
          this.searchItemToHighlight = null;
        } else {
          this.searchItemToHighlight = { id, drawingId };
        }
      },
    });
  }

  public componentWillUnmount(): void {
    document.removeEventListener('touchend', this.forceStopDrag);
    this.removeEventListeners();
    this.spaceBarListener.disableEvents();
    this.saveRendererApi(null);
    if (this.engine) {
      this.engine.destroy();
    }
  }

  private showAim(): boolean {
    return this.props.wizzardToolsSettings.wizzardState.status !== WizzardStatus.Eraser
      && DrawingsCommonUtils.isDrawEnabled(this.props.drawMode)
            || this.props.drawMode === DrawingsDrawMode.Sticker
            || this.props.drawMode === DrawingsDrawMode.Dropper;
  }

  private getDrawModeFromProps(props: Props): DrawingsDrawMode {
    let drawMode = props.drawMode;
    if (drawMode === DrawingsDrawMode.Dropper) {
      drawMode = WizzarsStatusToDropperDrawMode[props.wizzardToolsSettings.wizzardState.status];
    } else if (drawMode === DrawingsDrawMode.Finder) {
      if (props.wizzardToolsSettings.wizzardState.status === WizzardStatus.Start) {
        drawMode = props.wizzardToolsSettings.finderSelectionArea
          ? DrawingsDrawMode.Finder
          : DrawingsDrawMode.FinderSelectionArea;
      } else {
        drawMode = WizzardStatusToFinderDrawMode[props.wizzardToolsSettings.wizzardState.status];
      }
    } else if (drawMode === DrawingsDrawMode.MagicSearch) {
      drawMode = props.magicSearchSpecifyZone ? DrawingsDrawMode.MagicSearchArea : DrawingsDrawMode.MagicSearch;
    } else if (drawMode === DrawingsDrawMode.AutoMeasure2) {
      drawMode = props.magicSearchSpecifyZone ? DrawingsDrawMode.MagicSearchArea : DrawingsDrawMode.AutoMeasure2;
    }
    return drawMode;
  }

  @autobind
  private getDefaultCanvasRef(): HTMLCanvasElement {
    return this.props.viewportHelper.defaultCanvasRef;
  }

  private get isBooleanDrawingMode(): boolean {
    return DrawingsCommonUtils.isBooleanDrawMode(this.props.drawMode);
  }

  @autobind
  private removeTempSticker(): void {
    this.props.setTempStickerPosition(null);
  }

  @autobind
  private clearAnnotations(): void {
    if (this.engine) {
      this.engine.clearAnnotations();
    }
  }

  @autobind
  private getAnnotationBounds(ids: string[]): DrawingsBounds {
    if (this.engine) {
      return this.engine.getAnnotationsBounds(ids);
    }
    return null;
  }

  @autobind
  private saveStickersLayoutApi(api: DrawingsStickerLayoutApi): void {
    this.stickerLayoutApi = api;
  }

  @autobind
  private saveLegendApi(api: DrawingsUserAnnotationsLegendApi): void {
    this.legendApi = api;
  }

  @autobind
  private setSpaceBarHeld(isSpaceBarHeld: boolean): void {
    this.sharedSpaceBarHeldContext.updateContext(isSpaceBarHeld);
    this.changeCursorBySpaceBarHeld(isSpaceBarHeld);
    this.setState({ isSpaceBarHeld });
  }

  private changeCursorBySpaceBarHeld(isSpaceBarHeld: boolean): void {
    this.cursorTypeHelper.grabLayerEnabled = isSpaceBarHeld;
  }

  private updateDragStatus(value: boolean): void {
    this.cursorTypeHelper.grabbing = value;
  }

  @autobind
  private onDragStart(): void {
    this.updateDragStatus(true);
  }

  @autobind
  private onDragFinish(): void {
    this.updateDragStatus(false);
  }

  private updateEngineParameters(currentProps: Props, prevProps: Props): void {
    if (!this.engine) {
      return;
    }

    if (currentProps.offsetIsStroke !== prevProps.offsetIsStroke) {
      this.engine.offsetIsStroke = currentProps.offsetIsStroke;
    }

    if (currentProps.snappingModes !== prevProps.snappingModes) {
      this.engine.snappingModes = currentProps.snappingModes;
    }

    this.engine.isSnappingEnabled = currentProps.isSnappingEnabled;
    if (this.engine.isDrawingMode && currentProps.layoutFocused !== prevProps.layoutFocused) {
      if (currentProps.layoutFocused) {
        this.engine.initDrawKeyListeners();
      } else {
        this.engine.removeDrawKeyListeners();
      }
    }
  }

  private initEngineIfNeeded(prevProps: Props, prevState: ComponentState): void {
    const hasSelectedDrawing = this.hasSelectedDrawing(this.props, this.state);
    const prevHasSelectedDrawing = this.hasSelectedDrawing(prevProps, prevState);
    const is3dChanged = this.props.is3d !== prevProps.is3d;
    if ((hasSelectedDrawing && !prevHasSelectedDrawing || is3dChanged) && !this.props.is3d) {
      this.spaceBarListener.initEvents();
      this.engine = new DrawingRendererEngine(
        {
          magicSearchDataObserver: this.props.magicSearchContextObserver,
          keyboardListenerApi: {
            addKeyDownEventListener: this.props.addKeyDownEventListener,
            addKeyUpEventListener: this.props.addKeyUpEventListener,
            removeKeyUpEventListener: this.props.removeKeyUpEventListener,
            removeKeyDownEventListener: this.props.removeKeyDownEventListener,
            focus: this.props.focus,
          },
          snappingObserver: this.props.snappingObserver,
          newDrawingStylesObserver: this.newDrawingStylesObserver,
          editPermissionsObserver: this.editPermissionsObserver,
          measurementsAutocompleteContext: this.measurementsAutocompleteContext,
          orthogonalModeController: this.orthogonalStatusController,
          sharedSpaceBarHeldContext: this.sharedSpaceBarHeldContext,
          cursorTypeHelper: this.cursorTypeHelper,
          dragEventsHelper: this.dragGeometryEventsHelper,
          viewHelper: this.drawingViewHelper,
          getSnappingPoint: this.props.getSnappingPoint,
          undoRedoChangeActiveStatus: this.props.undoRedoChangeActiveStatus,
          removeInstancesWithUndo: this.props.removeInstancesWithUndo,
          textRenderParamsObserver: this.drawingTextParamsObserver,
          updateGeometry: this.props.updateGeometry,
          getColorOfInstance: this.getColorOfInstance,
          geometryRenderParamsObserver: this.props.zoomObserver,
          getPoint: (point) => new paper.Point(point),
          onSelectInstance: this.onInstanceSelect,
          onSelectSegment: this.props.selectSegment,
          sendMeasuresUpdate: this.onInstancesMeasuresUpdate,
          changeOffsetPosition: this.changeOffsetPosition,
          openContextMenu: this.openContextMenu,
          openFinishDrawPopup: this.setCompleteDrawingPopupPosition,
          changePointsPosition: this.onChangePointsPosition,
          onCalibrateLineDrawn: this.props.onCalibrateLineDrawn,
          changeDrawMode: mode => this.props.setDrawMode(mode),
          getCurrentPageInfo: () => this.props.viewportHelper.currentPageInfo,
          getNewInstanceName: () => this.props.newInstancesSettings.name,
          getCurrentDrawing: () => this.props.drawingsInfo[this.props.currentDrawingId],
          getPointCoordinates: pointId => this.props.points[pointId],
          getDrawingInstance: this.getDrawingInstance,
          getPointInfo: pointId => this.props.pointsInfo[pointId],
          checkInstanceVisibility: instanceId => !this.props.hiddenIds.includes(instanceId),
          getInstanceMeasures: instanceId => this.props.getActuralInstancesMeasures([instanceId])[0],
          annotationsEvents: this.userAnnotationEventProcessor,
          getImageInfo: id => this.props.userAnnotationState.images[id],
          getRulerInfo: id => this.props.userAnnotationState.rulers[id],
          copyPasteHelper: this.props.copyPasteBuffer,
          keepOriginName: this.props.isKeepOriginName,
          getColorOfLabel: this.getColorOfLabel,
          onFinishSelectionByArea: this.finishSelectByArea,
          stickersInRectIdsIterator: this.stickersInRectIdsIterator,
          onAnnotationsSelectionChanged: this.onAnnotationsSelectionChanged,
          onBatchUpdateGeometries: this.onBatchUpdateGeometries,
          geometryConversionProcessor: this.geometryConversionProcessor,
          onZoomAreaDrawn: this.props.viewportHelper.focusToBounds,
          onHoverInstanceChanged: this.onHoverInstanceChanged,
          setCursorHint: this.props.setCursorHintType,
          wizzardSettingsObserver: this.wizzardSettingsObserver,
          onChangeDropperState: this.props.setWizzardState,
          onWizzardWorkingArea: this.setWizzarWorkingdArea,
          onWizzardSelectionArea: this.props.setWizzardSelectionArea,
          addFinderSelectedGeometryToRemove: this.props.addFinderSelectedGeometryToRemove,
          clearWizzardResult: this.props.clearWizzardResult,
          onRunDropper: this.props.runDropper,
          setMagicSearchZone: this.props.setMagicSearchZone,
          onToggleMagicSearchPreviewStatus: this.props.toggleMagicSearchPreviewStatus,
          sendFinishApi: this.props.sendFinishGeometryApi,
          onMagicSearchPreviewStartFix: this.onMagicSearchPreviewStartFix,
          applyMagicSearchFix: this.props.applyMagicSearchFix,
          updateMagicSearchFixStatus: this.props.updateMagicSearchFixStatus,
          onPdfFilterArea: this.setPdfFilterArea,
          oneClickAreaHoverContextObserver: this.props.oneClickAreaHoverObserver,
        },
      );
      this.engine.offsetIsStroke = this.props.offsetIsStroke;
      this.engine.isSnappingEnabled = this.props.isSnappingEnabled;
      this.engine.snappingModes = this.props.snappingModes;
      const drawMode = this.getDrawModeFromProps(this.props);
      this.dragGeometryEventsHelper.drawMode = drawMode;
      this.engine.changeDrawMode(drawMode);
      this.engine.filteredInstancesIds = this.props.filteredElementIds;
      this.engine.hiddenInstancesIds = this.props.hiddenIds;
      if (this.props.fileData) {
        this.engine.renderInstances(this.props.fileData.instances);
      }
      this.engine.changeSelection(this.props.selectedInstances);
      if (this.props.userAnnotationState.annotationLoadingStatus === RequestStatus.Loaded) {
        const { images, rulers } = this.props.userAnnotationState;
        this.engine.renderAnnotations(images, rulers);
      }
      this.saveRendererApi({
        changeEntitiesStyle: this.changeEntitiesStyle,
        setIdsForOperation: this.setIdsForOperation,
        engine: this.engine,
        selectAll: this.selectAll,
        hasNotFinishedGeometry: () => this.hasUnsavedInstances(),
        canPaste: this.props.copyPasteBuffer.canPaste,
        canPasteInSamePlace: this.canPasteToSamePlace,
        duplicateInstancesToPage: this.duplicateInstancesToPage,
        addInstancesWithUndo: (payload) => this.props.addInstancesWithUndo(payload),
      });
      if (this.textSearch) {
        this.textSearch.destroy();
      }
      this.textSearch = new TextSearch({
        paramsObserver: this.props.zoomObserver,
        onSelectTextForSearch: this.props.onSelectTextForSearch,
        cursorHelper: this.cursorTypeHelper,
      });
      this.textSearch.setResults(this.props.currentDrawingId, this.props.textSearch.results.results);
      if (this.props.currentDrawingId === this.props.textSearch.textRectangles?.drawingId) {
        this.textSearch.setRects(this.props.textSearch.textRectangles);
      }
      if (this.searchItemToHighlight) {
        this.textSearch.setHighlightItem(this.searchItemToHighlight.drawingId, this.searchItemToHighlight.id);
        this.searchItemToHighlight = null;
      }
    } else if (!hasSelectedDrawing && prevHasSelectedDrawing || is3dChanged) {
      if (this.engine) {
        this.textSearch.destroy();
        this.engine.destroy();
        this.engine = null;
        this.spaceBarListener.disableEvents();
        paper.project.clear();
      }
      this.saveRendererApi(null);
    }
  }

  private hasUnsavedInstances(): boolean {
    if (this.engine?.hasUnsavedInstances) {
      return true;
    }
  }


  @autobind
  private onMagicSearchPreviewStartFix(fixContourState: FixContour): void {
    if (fixContourState) {
      const { previews, fixedContours } = this.props.magicSearchContextObserver.getContext();
      const contour = fixContourState.contourType === ContourToFixType.Fixed
        ? fixedContours[fixContourState.contourIndex].points
        : previews[fixContourState.contourIndex].points;
      const box = contour.reduce<[number, number, number, number]>((prev, [x, y]) => {
        prev[0] = Math.min(prev[0], x);
        prev[1] = Math.min(prev[1], y);
        prev[2] = Math.max(prev[2], x);
        prev[3] = Math.max(prev[3], y);
        return prev;
      }, [Infinity, Infinity, -Infinity, -Infinity]);
      const bounds: DrawingsBounds = {
        x: box[0],
        y: box[1],
        width: box[2] - box[0],
        height: box[3] - box[1],
      };
      this.props.viewportHelper.updateSelectedBounds(bounds);
    } else if (!this.props.selectedInstances.length) {
      this.props.viewportHelper.updateSelectedBounds(null, null);
    }
    this.props.sendAiSearchFixApi(fixContourState);
  }

  @autobind
  private setPdfFilterArea(area: ShortPointDescription[]): void {
    const { currentDrawingId, drawingsInfo } = this.props;
    const { drawingId, pdfId } = drawingsInfo[currentDrawingId];
    this.props.addToPdfFilter(pdfId, drawingId, area);
  }

  @autobind
  private setWizzarWorkingdArea(area: ShortPointDescription[]): void {
    this.props.setWizzardWorkingArea(area);
    switch (this.props.drawMode) {
      case DrawingsDrawMode.Dropper:
        this.props.setWizzardState(WizzardStatus.Start);
        break;
      case DrawingsDrawMode.Finder:
        this.props.setWizzardState(WizzardStatus.Start);
        break;
      default:
    }
  }

  @autobind
  private onChangePointsPosition(
    points: Record<string, ShortPointDescription>,
    newMeasures: DrawingsInstanceMeasure[],
  ): void {
    const redo = this.getUpdatePointsMethod(points, newMeasures, true);
    const oldPoints = arrayUtils.toDictionary(Object.keys(points), p => p, p => this.props.points[p]);
    const oldMeasures = newMeasures.map(
      ({ id }) => this.props.elementMeasurement[
        Array.isArray(id) ? DrawingAnnotationUtils.getLineKey(id[0], id[1]) : id
      ],
    );
    const undo = this.getUpdatePointsMethod(oldPoints, oldMeasures, true);
    this.getUpdatePointsMethod(points, newMeasures, false)();
    this.props.addUndoRedo(undo, redo);
  }

  private getUpdatePointsMethod(
    points: Record<string, ShortPointDescription>,
    measures: DrawingsInstanceMeasure[],
    updateSelectionRect: boolean,
  ): () => void {
    return () => {
      if (this.engine) {
        this.engine.updatePointPositions(points, updateSelectionRect);
      }
      this.props.onFinishEditAnnotation(points, measures);
      this.onInstancesMeasuresUpdate(measures);
    };
  }

  @autobind
  private duplicateInstancesToPage(instancesIds: string[], pageId: string): void {
    const { instances, points } = this.props;
    const instancesIter = arrayUtils.mapIterator(instancesIds, (x) => instances[x]);
    const { points: newPoints, instances: newInstances } = InstancesUtils.copyInstancesToPage(
      instancesIter,
      points,
      pageId,
    );
    this.props.addInstancesWithUndo({
      points: newPoints,
      instances: newInstances,
    });
  }

  @autobind
  private onBatchUpdateGeometries(
    changes: DrawingsBatchUpdateGeometries,
    newMeasures?: DrawingsInstanceMeasure[],
  ): void {
    const drawingId = this.props.currentDrawingId;
    const groupsPayload = this.props.getGroupPayloadForInstanceCreate();
    if (changes.newIdsToSource) {
      changes.pia = Object.entries(changes.newIdsToSource).reduce((prev, [id, source]) => {
        if (this.props.pia[source]) {
          prev[id] = this.props.pia[source];
        }
        return prev;
      }, {});
    }
    const { undo, redo } = DrawingsUndoRedoHelper.createBooleanEditUndoRedo(
      changes,
      newMeasures,
      pointId => this.props.points[pointId],
      instanceId => this.props.instances[instanceId],
      instancesToAdd => this.props.addInstances(instancesToAdd, groupsPayload),
      ids => {
        if (this.engine) {
          this.engine.removeInstancesByIds(ids);
        }
        this.props.removeInstances({ [drawingId]: ids });
      },
      this.onInstancesMeasuresUpdate,
      (updates) => {
        if (this.engine) {
          this.engine.updateInstanceGeometry(updates);
        }
        this.props.updateGeometry(updates);
      },
      id => this.props.elementMeasurement[id],
      id => this.props.pia[id],
    );

    this.props.addUndoRedo(undo, redo);
    if (changes.updated) {
      this.props.updateGeometry(changes.updated);
    }
    if (changes.removedInstances && changes.removedInstances.length) {
      this.props.removeInstances({ [ drawingId]: changes.removedInstances });
    }

    const shouldRunFindSimilar = this.shouldRunFindSimilar(changes);

    if (changes.addedInstances && changes.addedInstances.length) {
      this.props.addInstances({
        instances: changes.addedInstances,
        points: changes.newPoints,
        moveToSelectedGroup: changes.moveToSelectedGroup,
        ignoreSaveMeasuresOnCreate: changes.ignoreSaveMeasureOnCreate,
        pia: changes.pia,
        forceSave: shouldRunFindSimilar,
        analyticsParams: changes.analyticsParams,
      });
    }

    if (newMeasures) {
      this.onInstancesMeasuresUpdate(newMeasures, changes.removedInstances);
    }

    if (shouldRunFindSimilar) {
      this.runFindSimilarIfNeeded(changes);
    }
  }

  @autobind
  private runFindSimilarIfNeeded(changes: DrawingsBatchUpdateGeometries): void {
    const contours = changes.addedInstances.map(x => {
      const points = x.geometry.points.map(p => changes.newPoints[p]);
      points.push(points[0]);
      return points;
    });

    this.props.setDrawMode(DrawingsDrawMode.MagicSearch, {
      ignoreCancelMessage: true,
      prevSave: () => {
        this.props.setContoursToMagicSearch(
          contours,
          this.props.drawMode,
        );
      },
    });
  }

  private shouldRunFindSimilar(changes: DrawingsBatchUpdateGeometries): boolean {
    const { drawMode, wizzardToolsSettings, newInstancesSettings } = this.props;
    if (!newInstancesSettings.shouldSearchSimilar || !changes.isDrawn) {
      return false;
    }

    return ClosedContoursDrawModes.includes(drawMode)
      || (drawMode === DrawingsDrawMode.Wand && wizzardToolsSettings.enclose && wizzardToolsSettings.connect);
  }

  @autobind
  private getPositionOnLayer(point: ShortPointDescription): ShortPointDescription | null {
    const { rotation, zoomObserver } = this.props;
    return DrawingsCanvasUtils.getPointOnLayer(point, zoomObserver.getContext().zoom, rotation);
  }

  @autobind
  private startContinue(): void {
    const { selectedInstances, instances } = this.props;
    const instanceId = selectedInstances.find(x => {
      const type = instances[x].type;
      return type === DrawingsInstanceType.Count || type === DrawingsInstanceType.Polyline;
    });
    if (!instanceId) {
      return;
    }
    const count = [instanceId];
    this.props.selectInstances(count);
    this.engine.changeSelection(count);
    this.props.setDrawMode(DrawingsDrawMode.Continue);
  }

  @autobind
  private selectAnnotation(id: string, ctrlKey: boolean): void {
    if (this.props.drawMode === DrawingsDrawMode.Modify) {
      return;
    }
    if (ctrlKey) {
      if (this.props.selectedAnnotations.includes(id)) {
        this.props.selectAnnotations(this.props.selectedAnnotations.filter(x => x !== id));
      } else {
        this.props.selectAnnotations(this.props.selectedAnnotations.concat(id));
      }
    } else {
      this.props.selectAnnotations([id]);
    }
    this.props.selectInstances([]);
  }

  @autobind
  private getCanvasPosition(point: paper.Point): paper.Point {
    const { zoomObserver } = this.props;
    return point
      .divide(zoomObserver.getContext().zoom)
      .add(new paper.Point(paper.view.bounds.x, paper.view.bounds.y));
  }

  @autobind
  private getDrawingInstance(instanceId: string): DrawingsGeometryInstance {
    return this.props.instances[instanceId];
  }

  private renderIfNeeded(currentProps: Props, prevProps: Props): void {
    if (!this.engine) {
      return;
    }

    const { annotationLoadingStatus, rulers, images } = currentProps.userAnnotationState;
    if (annotationLoadingStatus !== prevProps.userAnnotationState.annotationLoadingStatus) {
      if (annotationLoadingStatus === RequestStatus.Loaded) {
        this.engine.renderAnnotations(images, rulers);
      } else {
        this.engine.clearAnnotations();
      }
    }

    const isIsolativeDrawMode = DrawingsCommonUtils.isIsolativeDrawMode(currentProps.drawMode);

    const isolationDisabled = !isIsolativeDrawMode
      && DrawingsCommonUtils.isIsolativeDrawMode(prevProps.drawMode);

    if (isolationDisabled) {
      this.engine.filteredInstancesIds = this.props.filteredElementIds;
      if (DrawingsCommonUtils.isBooleanDrawMode(prevProps.drawMode)) {
        this.setIdsForOperation(null, DrawingGeometryOperationType.Boolean);
      } else if (prevProps.drawMode === DrawingsDrawMode.Offset) {
        this.setIdsForOperation(null, DrawingGeometryOperationType.Offset);
      } else if (prevProps.drawMode === DrawingsDrawMode.Knife) {
        this.setIdsForOperation(null, DrawingGeometryOperationType.Knife);
      }
      this.changeElementsSelection(this.props.selectedInstances);
    }

    if  (this.props.drawMode === DrawingsDrawMode.Finder && prevProps.drawMode !== DrawingsDrawMode.Finder) {
      this.setIdsForOperation([], null);
    } else if (this.props.drawMode !== DrawingsDrawMode.Finder && prevProps.drawMode === DrawingsDrawMode.Finder) {
      this.engine.filteredInstancesIds = this.props.filteredElementIds;
      if (this.props.fileData) {
        this.engine.renderInstances(this.props.fileData.instances);
      }
    }

    const filteredChanged = currentProps.filteredElementIds !== prevProps.filteredElementIds;
    if (filteredChanged) {
      this.engine.filteredInstancesIds = currentProps.filteredElementIds;
    }
    if (
      currentProps.currentDrawingId !== prevProps.currentDrawingId
      || (!prevProps.fileData && currentProps.fileData)
    ) {
      this.engine.removeOld();
      if (currentProps.fileData) {
        this.engine.renderInstances(this.props.fileData.instances);
      }
    } else if ((filteredChanged && !isIsolativeDrawMode) || isolationDisabled) {
      if (!currentProps.fileData) {
        return;
      }
      this.engine.renderInstances(this.props.fileData.instances);
    }
  }

  @autobind
  private setCompleteDrawingPopupPosition(e: PaperMouseEvent, api: FinishDrawApi, config: FinishDrawConfig): void {
    this.setState({
      completeDrawMenuPosition: e ? this.getContextMenuPosition(e) : null,
      completeDrawMenuConfig: config,
      completeDrawMenuApi: api,
    });
  }

  @autobind
  private onCloseFinishEditMenu(): void {
    this.engine.mouseMoveDisabled = false;
    this.setState({ completeDrawMenuPosition: null });
  }

  private hasSelectedDrawing(
    { currentDrawingId, drawingRendered }: Props,
    { drawingCanvasRef }: ComponentState,
  ): boolean {
    return !!(currentDrawingId && drawingRendered && drawingCanvasRef);
  }

  @autobind
  private changeOffsetPosition(point: paper.Point, value: number): void {
    this.props.viewportHelper.saveOffsetPosition(point);
    this.props.setOffsetValue(
      DrawingsCanvasUtils.pxToMetres(Math.abs(value), this.props.scale, this.props.metersPerPixel),
    );
  }

  @autobind
  private onRemoveInstancesHandler(onlyInstances?: boolean, preRemoveAction?: () => void): void {
    if (DrawingsModesWithEnabledDelete.includes(this.props.drawMode)) {
      this.removeSelectedInstances(onlyInstances, preRemoveAction);
    }
  }

  @autobind
  private removeSelectedInstances(onlyInstances?: boolean, preRemoveAction?: () => void): void {
    if (this.engine) {
      this.engine.dragHelper.setCallback(null);
    }

    if (this.props.drawMode === DrawingsDrawMode.Modify || this.props.drawMode === DrawingsDrawMode.Continue) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled);
    }
    this.props.removeSelectedInstancesHandler(onlyInstances, preRemoveAction);
    if (this.props.selectAnnotations.length && this.engine) {
      this.removeAnnotationsWithUndo(this.props.selectedAnnotations);
    }
  }

  @autobind
  private removeAnnotationsWithUndo(annotationsIds: string[]): void {
    const { undo, redo } = DrawingsUndoRedoHelper.removeAnnotationsUndo(
      annotationsIds,
      this.props.currentDrawingId,
      this.props.userAnnotationState,
      this.removeAnnotations,
      this.engine.renderAnnotations,
      this.addImages,
      this.addRulers,
      this.addStickers,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private showAll(): void {
    this.props.changeShowInstances(this.props.hiddenIds);
  }

  @autobind
  private addImages(images: DrawingUserAnnotationImage[], pageId: string): void {
    this.props.addImages(images, pageId);
    this.props.sendEvent(MetricNames.annotaion.createSymbol);
  }

  @autobind
  private addRulers(rulers: DrawingUserAnnotationRuler[], pageId: string): void {
    this.props.addRulers(rulers, pageId);
    this.props.sendEvent(MetricNames.annotaion.createDimensionLine);
  }

  @autobind
  private addStickers(stickers: DrawingUserAnnotationSticker[], pageId: string): void {
    this.props.addStickers(stickers, pageId);
    this.props.sendEvent(MetricNames.annotaion.createStiker);
  }

  @autobind
  private removeAnnotations(ids: string[], pageId: string): void {
    this.props.removeAnnotations(ids, pageId);
    if (this.engine) {
      this.engine.removeAnnotations(ids);
    }
  }

  @autobind
  private getInstanceMeasures(
    id: string,
    geometry: Record<string, DrawingsGeometryInstance<DrawingsGeometryType>>,
  ): DrawingsInstanceMeasure {
    return this.props.rendererApi && this.props.rendererApi.engine.getInstanceMeasures(id, geometry);
  }

  @autobind
  private unselect(): void {
    if (!DrawingsCommonUtils.isDrawEnabled(this.props.drawMode)) {
      this.props.selectInstances([]);
      this.clearGroupSelection();
    }
    if (this.props.drawMode === DrawingsDrawMode.Offset) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled);
    }
  }

  private addEventListeners(): void {
    this.keyboardListener.addEventListeners(this.props.canViewMeasurement);
    if (!DrawingsCommonUtils.isDrawEnabled(this.props.drawMode)) {
      this.onlySelectKeyboardListener.addEventListeners(this.props.canEditMeasurement);
    }
  }

  private removeEventListeners(): void {
    this.keyboardListener.removeEventListeners(this.props.canViewMeasurement);
    this.onlySelectKeyboardListener.removeEventListeners(this.props.canEditMeasurement);
  }

  @autobind
  private selectAll(): void {
    const selectAll = (): void => {
      if (!this.props.fileData) {
        return;
      }
      if (this.props.rendererApi) {
        const visibleIds = this.props.rendererApi.engine.getVisibleInstancesIds(this.props.fileData.instances);
        this.props.selectDrawingGroups(visibleIds);
      } else {
        const hiddenSet = new Set(this.props.hiddenIds || []);
        const filteredIds = new Set(this.props.filteredElementIds || []);
        const ids = this.props.fileData.instances.filter(x => !hiddenSet.has(x) && filteredIds.has(x));
        this.props.selectDrawingGroups(ids);
      }
    };
    if (DrawingsCommonUtils.isDrawEnabled(this.props.drawMode) || MagicSearchDrawModes.includes(this.props.drawMode)) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled, {
        afterSave: selectAll,
      });
    } else {
      selectAll();
    }
  }

  private changeElementsSelection(ids: string[]): void {
    if (this.engine) {
      this.engine.changeSelection(ids);
    }
  }

  @autobind
  private saveRendererApi(api: DrawingsGeometryRendererAPI): void {
    this.props.setRendererApi(api);
  }

  @autobind
  private focus(): void {
    if (this.props.selectedInstances.length) {
      this.props.onFocus(this.props.selectedInstances, true);
    }
  }

  @autobind
  private cut(): void {
    // todo: убрать проверку после https://kreosoftware.atlassian.net/browse/KREOP-12956
    if (this.props.selectedInstances.length) {
      this.props.afterCopy(this.props.selectedInstances);
      this.onRemoveInstancesHandler(true, () => this.props.copyPasteBuffer.cut(this.props.selectedInstances));
    }
  }

  @autobind
  private copyCurrentSelectedInstances(): void {
    this.props.copyPasteBuffer.copy(this.props.selectedInstances);
    this.props.afterCopy(this.props.selectedInstances);
  }

  @autobind
  private pasteInstances(coordinates: paper.Point): void {
    const center = coordinates || paper.view.center;
    this.props.copyPasteBuffer.pasteWithCenter(center, this.props.currentDrawingId);
  }

  @autobind
  private isSelectedInstancesHidden(): boolean {
    const hiddenIdsMap = new Set(this.props.hiddenIds);
    return this.props.selectedInstances.every((item) => hiddenIdsMap.has(item));
  }

  @autobind
  private canPasteToSamePlace(): boolean {
    const currentDrawing = this.props.drawingsInfo[this.props.currentDrawingId];
    if (!currentDrawing) {
      return false;
    }
    return this.props.copyPasteBuffer.canPasteToZeroPoint(currentDrawing);
  }

  @autobind
  private pasteToSamePlace(): void {
    this.props.copyPasteBuffer.pasteToSamePosition(this.props.currentDrawingId);
  }

  @autobind
  private pasteWithPoint(): void {
    if (this.props.copyPasteBuffer.canPaste()) {
      const currentDrawing = this.props.drawingsInfo[this.props.currentDrawingId];
      if (
        this.props.copyPasteBuffer.isSameDrawingToPaste(this.props.currentDrawingId)
        || (currentDrawing.drawingCalibrationLineLength && currentDrawing.originalCalibrationLineLength)) {
        this.props.setDrawMode(DrawingsDrawMode.PasteWithPoint);
      } else {
        this.props.openNotCalibratedDialog({
          descriptionType: NoScaleDescriptionType.Default,
          onSkip: () => this.props.setDrawMode(DrawingsDrawMode.PasteWithPoint),
        });
      }
    }
  }

  @autobind
  private changeEntitiesStyle<T extends keyof DrawingsGeometryStyle>(
    ids: string[],
    field: T,
    value: DrawingsGeometryStyle[T],
  ): void {
    this.engine.changeEntitiesStyles(ids, field, value);
  }

  @autobind
  private changeEntitiesColor(ids: string[], color: string): void {
    const entitiesToUpdate = ids.filter(id => this.getColorOfInstance(id) !== color);
    if (entitiesToUpdate) {
      this.changeColor(entitiesToUpdate, color);
    }
  }

  @autobind
  private changeColor(ids: string[], color: string): void {
    this.engine.changeEntitiesStyles(ids, 'color', color);
    this.props.updateInstanceStyleParameter(ids, 'color', color);
  }

  @autobind
  private onInstanceSelect(id: string, e?: PaperMouseEvent | React.MouseEvent<HTMLDivElement> | MouseEvent): void {
    if (!id) {
      this.unselect();
      return;
    }
    const { drawMode } = this.props;
    let newSelection: string[] = null;
    if (drawMode === DrawingsDrawMode.Union || drawMode === DrawingsDrawMode.Join) {
      return;
    }
    if (drawMode === DrawingsDrawMode.Subtract) {
      this.props.setSelectedIdForSubtract(id);
      this.engine.changeSelection([id]);
    } else if (drawMode === DrawingsDrawMode.Calibrate && id === DrawingsCanvasConstants.calibrateLineId) {
      newSelection = [id];
    } else if (!DrawingsCommonUtils.isDrawEnabled(drawMode)) {
      if (e && e.type === 'doubleclick') {
        newSelection = [id];
      } else {
        let ctrlKey: boolean;
        if (e) {
          ctrlKey = HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(DrawingsPaperUtils.isPaperMouseEvent(e) ? e.event : e);
        }
        if (ctrlKey) {
          if (this.props.selectedInstances.includes(id)) {
            newSelection = this.props.selectedInstances.filter(x => x !== id);
            this.props.syncGroupSelection(id);
          } else {
            newSelection = this.props.selectedInstances.concat(id);
          }
        } else if (this.props.selectedInstances.length !== 1 || this.props.selectedInstances[0] !== id) {
          newSelection = [id];
        } else {
          return;
        }
      }
    } else {
      newSelection = [id];
    }
    if (newSelection) {
      this.props.selectInstances(newSelection);
    }
  }

  @autobind
  private saveDrawingCanvasRef(drawingCanvasRef: HTMLCanvasElement): void {
    this.drawingViewHelper.init(drawingCanvasRef);
    this.setState({ drawingCanvasRef });
  }

  @autobind
  private onMouseMove(e: PaperMouseEvent): void {
    if (this.engine) {
      this.engine.dragHelper.onMouseMove(e);
      this.selectionEventHelper.onMouseMouseMove(e);
      if (this.props.textSearch.isActive) {
        this.textSearch.onMouseMove(e);
      }
    }
  }

  @autobind
  private *stickersInRectIdsIterator(): IterableIterator<string> {
    return this.stickerLayoutApi.stickersInRectIdsIterator;
  }

  @autobind
  private onAnnotationsSelectionChanged(annotationIds: string[], ctrlKey: boolean): void {
    if (ctrlKey) {
      this.props.selectAnnotations(arrayUtils.symDiff(annotationIds, this.props.selectedAnnotations));
    } else {
      this.props.selectAnnotations(annotationIds);
    }
  }

  @autobind
  private onMouseDown(e: PaperMouseEvent): void {
    const { drawMode, marqueeZoomEnabled } = this.props;
    if (
      PaperMouseEventUtils.isLeftMouseButton(e)
      && (drawMode === DrawingsDrawMode.Disabled || drawMode === DrawingsDrawMode.Modify)
    ) {
      const callback = marqueeZoomEnabled ? this.engine.startZoomByArea : this.engine.startSelectionByArea;
      this.selectionEventHelper.onMouseDown(e, callback);
    }
  }

  @autobind
  private finishSelectByArea(instancesIds: string[], ctrlKey: boolean): void {
    if (ctrlKey) {
      this.props.selectInstances(arrayUtils.symDiff(instancesIds, this.props.selectedInstances));
    } else {
      if (instancesIds.length === 0) {
        this.deselectInstances();
        this.clearGroupSelection();
      } else {
        this.props.selectInstances(instancesIds);
      }
    }
  }

  @autobind
  private forceStopDrag(): void {
    if (this.engine) {
      this.engine.dragHelper.stopDrag();
    }
  }

  @autobind
  private onMouseUp(e: PaperMouseEvent): void {
    if (!this.engine.dragHelper.onMouseUp(e)) {
      this.selectionEventHelper.onMouseUp();
    }
  }

  @autobind
  private onLayoutLeftMouseButtonClick(e: PaperMouseEvent): void {
    if (
      !this.isBooleanDrawingMode
      && !DrawingsCommonUtils.isDrawEnabled(this.props.drawMode)
      && !HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e.event)
    ) {
      if (this.props.drawMode === DrawingsDrawMode.Sticker) {
        this.addSticker(e.point);
      } else if (this.props.drawMode === DrawingsDrawMode.Legend) {
        this.addLegend(e.point);
      } else {
        this.deselectAll();
      }
    }
  }

  private addSticker(point: paper.Point): void {
    if (!this.props.tempSticker || !this.props.tempSticker?.hasText) {
      this.props.setTempStickerPosition([point.x, point.y]);
    }
  }

  private addLegend(point: paper.Point): void {
    this.props.addLegend([point.x, point.y]);
    this.props.setDrawMode(DrawingsDrawMode.Disabled);
    this.props.sendEvent(MetricNames.annotaion.createLegend);
  }

  private deselectAll(): void {
    this.deselectInstances();
    this.deselectAnnotations();
    this.clearGroupSelection();
  }

  private deselectInstances(): void {
    if (this.props.selectedInstances.length) {
      this.props.selectInstances([]);
    }
  }

  private deselectAnnotations(): void {
    if (this.props.selectedAnnotations.length) {
      this.props.selectAnnotations([]);
    }
  }

  @autobind
  private onLayoutRightMouseButtonClick(e: PaperMouseEvent): void {
    e.event.stopPropagation();
    this.openContextMenu(e);
  }

  @autobind
  private onContextMenuClose(): void {
    this.props.setContextMenuPosition(null);
  }

  @autobind
  private onHoverInstanceChanged(e: HoverInstanceChangeEvent): void {
    if (e.hovered) {
      const event = PaperMouseEventUtils.getEvent(e.event);
      const mainRef = this.getMainLayoutRef();
      if (PaperMouseEventUtils.isTouch(event) || !mainRef) {
        return;
      }
      const mainRect = mainRef.getBoundingClientRect() as DOMRect;
      const x = event.clientX;
      const side = x - mainRect.x > mainRect.width / 2 ? InfoWindowPosition.LEFT : InfoWindowPosition.RIGHT;
      this.props.saveHoverState({ hoverInstanceId: e.id, position: side });
    } else {
      this.props.removeHoverState(e.id);
    }
  }

  @autobind
  private openContextMenu(e: PaperMouseEvent): void {
    const [x, y] = this.getContextMenuPosition(e);
    this.props.setContextMenuPosition({ x, y, drawingPoint: e.point });
  }

  private getContextMenuPosition(e: PaperMouseEvent): ShortPointDescription {
    const [x, y] = this.getPositionOnLayer([e.point.x, e.point.y]);
    const rect = this.state.drawingCanvasRef.getBoundingClientRect() as DOMRect;
    return [x + rect.x, y + rect.y];
  }

  @autobind
  private getMainLayoutRef(): HTMLDivElement {
    return this.mainRef;
  }

  @autobind
  private getScrollLayoutRef(): HTMLDivElement {
    return this.scrollLayoutRef;
  }

  @autobind
  private saveScrollLayoutRef(ref: HTMLDivElement): void {
    this.scrollLayoutRef = ref;
    this.props.viewportHelper.saveScrollLayout(ref);
  }

  @autobind
  private saveMainLayoutRef(ref: HTMLDivElement): void {
    this.mainRef = ref;
    this.props.viewportHelper.setMainLayoutRef(ref);
  }

  @autobind
  private getDrawingCanvas(): HTMLCanvasElement {
    return this.state.drawingCanvasRef;
  }

  private updateScaleIfNeeded(prevProps: Props): void {
    const { scale, metersPerPixel, rotation, isImperial, isLabelsEnabled, isShowThickness } = this.props;
    const scaleUpdated = prevProps.scale !== scale || prevProps.metersPerPixel !== metersPerPixel;
    if (
      scaleUpdated
      || rotation !== prevProps.rotation
      || isImperial !== prevProps.isImperial
      || isLabelsEnabled !== prevProps.isLabelsEnabled
      || isShowThickness !== prevProps.isShowThickness
    ) {
      this.drawingTextParamsObserver.updateContext(this.extractTextRenderParamsFromProps(this.props));
    }
  }

  @autobind
  private onInstancesMeasuresUpdate(measures: DrawingsInstanceMeasure[], removedInstances?: string[]): void {
    const { elementMeasurement, instances } = this.props;
    const groupMeasuresCache = this.props.userAnnotationState.groupsMeasuresCache;
    const processedGroups: Record<string, DrawingsGroupMeasure> = {};
    const processMeasureGroups = (
      groupId: string,
      measure: DrawingsInstanceMeasure,
      diffGetter: (value: number, valueKey: string) => number,
    ): void => {
      if (!groupId && !groupMeasuresCache[groupId]) {
        return;
      }
      processedGroups[groupId] = processedGroups[groupId] || { ...groupMeasuresCache[groupId] };

      for (const [key, value] of Object.entries(measure.measures)) {
        if (!(key in processedGroups[groupId])) {
          continue;
        }

        const diff = diffGetter(value, key);
        processedGroups[groupId][key] = processedGroups[groupId][key] + diff;
      }
    };

    if (removedInstances) {
      for (const instanceId of removedInstances) {
        const groupId = instances[instanceId].groupId;
        processMeasureGroups(groupId, elementMeasurement[instanceId], value => -value);
      }
    }

    for (const measure of measures) {
      if (Array.isArray(measure.id)) {
        continue;
      }
      const groupId = instances[measure.id].groupId;
      const instanceId = measure.id;
      processMeasureGroups(
        groupId,
        measure,
        (value, key) => value - elementMeasurement[instanceId].measures[key],
      );
    }
    this.props.onInstancesMeasuresUpdated(measures);
    this.props.cacheGroupMeasure(processedGroups);
  }

  @autobind
  private getColorOfLabel(label: string): string {
    return this.props.colors[label];
  }

  @autobind
  private setIdsForOperation(ids: string[], operation: DrawingGeometryOperationType): void {
    this.engine.setIdsForOperation(ids, operation);
  }

  @autobind
  private getColorOfInstance(
    id: string,
    geometry?: Record<string, DrawingsGeometryInstance<DrawingsGeometryType>>,
  ): string {
    const instance = (geometry && geometry[id]) || this.props.instances[id];
    return DrawingAnnotationUtils.getColorOfInstance(instance);
  }


  @autobind
  private clearGroupSelection(): void {
    this.props.setSelectGeometryGroup([]);
  }

  private extractTextRenderParamsFromProps(props: Props): MeasuresViewSettings {
    const {
      isImperial,
      rotation,
      metersPerPixel,
      scale,
      paperSize,
      isLabelsEnabled,
      isShowThickness,
    } = props;
    return {
      isImperial,
      rotation,
      metersPerPixel,
      scale,
      paperSize,
      showLabel: isLabelsEnabled,
      showThickness: isShowThickness,
    };
  }
}

function mapStateToProps(
  {
    drawings: {
      elementMeasurement,
      aiAnnotation: { pointsInfo, fileData },
      drawingsInfo,
      filteredElementIds,
      userAnnotations,
      hiddenInstances,
      selectGeometryGroup,
      textSearch,
      wizzardTools,
      is3d,
      magicSearch,
    },
    account,
    twoD,
  }: State,
  ownProps: OwnProps,
): StateProps {
  const drawingInfo = drawingsInfo[ownProps.currentDrawingId];
  return {
    pia: twoD.assignPia,
    userAnnotationState: userAnnotations,
    isImperial: account.settings.isImperial,
    pointsInfo,
    elementMeasurement,
    drawingsInfo,
    filteredElementIds,
    selectedAnnotations: userAnnotations.selectedAnnotations,
    hiddenIds: hiddenInstances,
    fileData: drawingInfo && fileData[drawingInfo.pdfId] && fileData[drawingInfo.pdfId][drawingInfo.drawingId],
    selectedGroups: selectGeometryGroup,
    rotation: drawingInfo?.rotationAngle,
    textSearch,
    tempSticker: userAnnotations.tempSticker,
    wizzardToolsSettings: wizzardTools,
    is3d,
    magicSearchSpecifyZone: magicSearch.searchAreaSpecifyMode,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onCalibrateLineDrawn: ({ pxLength }) => dispatch(CalibrateActions.onCalibrateLineDrawn(pxLength)),
    updateGeometry: (updates) => {
      dispatch(DrawingsAnnotationActions.updateAnnotationGeometry(updates));
    },
    updateInstanceStyleParameter: (instancesIds, parameter, color) => {
      dispatch(DrawingsAnnotationActions.updateGeometryParams(instancesIds, parameter, color));
    },
    addImages: (images, pageId) => {
      dispatch(DrawingsUserAnnotationActions.addImages(images, pageId));
    },
    addRulers: (rulers, pageId) => {
      dispatch(DrawingsUserAnnotationActions.addRulers(rulers, pageId));
    },
    addStickers: (stickers, pageId) => {
      dispatch(DrawingsUserAnnotationActions.addSticker(stickers, pageId));
    },
    removeAnnotations: (ids, pageId) => {
      dispatch(DrawingsUserAnnotationActions.removeAnnotations(ids, pageId));
    },
    selectAnnotations: ids => {
      dispatch(DrawingsUserAnnotationActions.selectAnnotations(ids));
    },
    onAnnotationColorChanged: colorChanges => {
      dispatch(DrawingsUserAnnotationActions.changeColorOfAnnotation(colorChanges));
    },
    updateImagesPositions: updates => {
      dispatch(DrawingsUserAnnotationActions.updateImagesPosition(updates));
    },
    updateImageParameter: (id, rotation, parameter) => {
      dispatch(DrawingsUserAnnotationActions.updateImageParameter(id, rotation, parameter));
    },
    updateRulerPositions: (id, points) => {
      dispatch(DrawingsUserAnnotationActions.updateRulerPoints(id, points));
    },
    addLegend: position => {
      dispatch(DrawingsUserAnnotationActions.updateLegend(position));
    },
    updateStickersPosition: updatedPositions => {
      dispatch(DrawingsUserAnnotationActions.updateStickersPosition(updatedPositions));
    },
    setContextMenuPosition: contextMenuPosition => {
      dispatch(DrawingsActions.setContextMenuPosition(contextMenuPosition));
    },
    setTempStickerPosition: position => dispatch(DrawingsUserAnnotationActions.setTempStickerPosition(position)),
    removeHoverState: (instanceId) => dispatch(DrawingsActions.removeHoverState(instanceId)),
    saveHoverState: (hoverState) => dispatch(DrawingsActions.setHoverState(hoverState)),
    setWizzardState: (dropperState, count) =>
      dispatch(WizzardToolsActions.setWizzardState(dropperState, count)),
    setWizzardWorkingArea: (area) => dispatch(WizzardToolsActions.setSettings('searchArea', area)),
    setMagicSearchZone: (zone) => dispatch(MagicSearchActions.setSearchingZone(zone)),
    setWizzardSelectionArea: (area) => dispatch(WizzardToolsActions.setSelectionArea(area)),
    addFinderSelectedGeometryToRemove: (id) => dispatch(WizzardToolsActions.addGeometriesToRemove([id])),
    clearWizzardResult: () => dispatch(WizzardToolsActions.setResult(null)),
    setCursorHintType: (type) => dispatch(DrawingsActions.setCursorHint(type)),
    runDropper: (point: ShortPointDescription) => dispatch(WizzardToolsActions.runDropper(point)),

    afterCopy: (ids) => dispatch(TwoDActions.afterCopyMeasure(ids)),
    setContoursToMagicSearch: (contours, values) => dispatch(MagicSearchActions.setContoursToSearch(contours, values)),
    toggleMagicSearchPreviewStatus: (ids: number[]) => dispatch(MagicSearchActions.toggleDeletePreview(ids)),
    openNotCalibratedDialog: (data) =>
      dispatch(KreoDialogActions.openDialog(DrawingDialogs.NOT_SCALED_DIALOG, data)),
    onSelectTextForSearch: (query: string) => {
      dispatch(DrawingsTextSearchActions.setQuery(query));
    },
    sendFinishGeometryApi: (api) => {
      dispatch(DrawingsActions.setGeometryFinishApi(api));
    },
    sendAiSearchFixApi: (api) => dispatch(MagicSearchActions.setFixContourApi(api)),
    applyMagicSearchFix:
      (sourceIndices, contour) => dispatch(MagicSearchActions.applyFixContour(sourceIndices, contour)),
    updateMagicSearchFixStatus: (status) => dispatch(MagicSearchActions.setFixContourStatus(status)),
    addToPdfFilter: (fileId, drawingId, area) => {
      dispatch(PdfFilterActions.addToFilter(fileId, drawingId, area));
    },
  };
}

export const DrawingsCanvas =
  withDrawingsCanvasContextWrapper(connect(mapStateToProps, mapDispatchToProps)(
    withAnalyticsContext(CanvasLayoutComponent)));
