import { useCallback } from 'react';

import { useActionDispatch } from 'common/hooks';
import { InvitationCompanyUsers } from 'common/interfaces/people';
import { useOpenCloseDialog } from 'common/UIKit';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { PeopleActions } from '../../actions/actions';
import { COMPANY_INFO_ADD_PEOPLE_DIALOG } from './constants';
import { RoleData } from './interfaces';

export function useInvitePeopleToCompany(
  companyId: number,
): (peopleInvites: Array<{ email: string, role: RoleData }>) => void {
  const invitePeople = useActionDispatch(PeopleActions.invitePeopleToCompanyNew);
  const [, closeDialog] = useOpenCloseDialog(COMPANY_INFO_ADD_PEOPLE_DIALOG);
  const { sendEvent } = useAnalytics();

  return useCallback((peopleInvites: Array<{ email: string, role: RoleData }>) => {
    const invites: Record<number, InvitationCompanyUsers> = {};
    for (const person of peopleInvites) {
      invites[person.role.id] =
        invites[person.role.id] || {
          companyId,
          emails: [],
          host: window.location.host,
          roleId: person.role.id,
        };
      invites[person.role.id].emails.push(person.email);
    }

    invitePeople(Object.values(invites));
    closeDialog();

    for (const invite of peopleInvites) {
      sendEvent(
        MetricNames.invitePeople.invitePeopleToCompany,
        {
          inviteEmails: [invite.email],
          role: invite.role.name,
        },
      );
    }
  }, [invitePeople, closeDialog, sendEvent, companyId]);
}
