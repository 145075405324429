import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';

function multiply([x1, y1]: ShortPointDescription, point2: ShortPointDescription | number): ShortPointDescription {
  return typeof point2 === 'number' ? [x1 * point2, y1 * point2] : [x1 * point2[0], y1 * point2[1]];
}

function divide([x1, y1]: ShortPointDescription, point2: ShortPointDescription | number): ShortPointDescription {
  return typeof point2 === 'number' ? [x1 / point2, y1 / point2] : [x1 / point2[0], y1 / point2[1]];
}

function subtract([x1, y1]: ShortPointDescription, point2: ShortPointDescription | number): ShortPointDescription {
  return typeof point2 === 'number' ? [x1 - point2, y1 - point2] : [x1 - point2[0], y1 - point2[1]];
}

function add([x1, y1]: ShortPointDescription, point2: ShortPointDescription | number): ShortPointDescription {
  return typeof point2 === 'number' ? [x1 + point2, y1 + point2] : [x1 + point2[0], y1 + point2[1]];
}

function squaredDist([x1, y1]: ShortPointDescription, [x2, y2]: ShortPointDescription): number {
  return (x1 - x2) ** 2 + (y1 - y2) ** 2;
}


function dotProduct(v1: ShortPointDescription, v2: ShortPointDescription): number {
  return v1[0] * v2[0] + v1[1] * v2[1];
}


function pointProjectionToSegment(
  point: ShortPointDescription,
  segmentStart: ShortPointDescription,
  segmentEnd: ShortPointDescription,
): ShortPointDescription {
  const [x, y] = point;
  const [x1, y1] = segmentStart;
  const [x2, y2] = segmentEnd;
  const abX = x2 - x1;
  const abY = y2 - y1;
  const apX = x - x1;
  const apY = y - y1;
  const startDotProduct = apX * abX + apY * abY;
  const abLengthSquared = abX * abX + abY * abY;
  const t = startDotProduct / abLengthSquared;
  const projX = x1 + t * abX;
  const projY = y1 + t * abY;
  return [projX, projY];
}

function peprendicularVectorToLine(
  lineStart: ShortPointDescription,
  lineEnd: ShortPointDescription,
): ShortPointDescription {
  const [x1, y1] = lineStart;
  const [x2, y2] = lineEnd;
  const dx = x2 - x1;
  const dy = y2 - y1;
  return [dy, -dx];
}

function equal([x1, y1]: ShortPointDescription, [x2, y2]: ShortPointDescription): boolean {
  return squaredDist([x1, y1], [x2, y2]) < 1e-6;
}


function isPointInPolygon(point: ShortPointDescription, polygon: ShortPointDescription[]): boolean {
  const [x, y] = point;
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const [xi, yi] = polygon[i];
    const [xj, yj] = polygon[j];
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}


function pointProjectionToPolyline(
  point: ShortPointDescription,
  polyline: ShortPointDescription[],
): ShortPointDescription {
  let minDist = Infinity;
  let projection = point;
  for (let i = 0; i < polyline.length - 1; i++) {
    const proj = pointProjectionToSegment(point, polyline[i], polyline[i + 1]);
    const dist = squaredDist(point, proj);
    if (dist < minDist) {
      minDist = dist;
      projection = proj;
    }
  }
  return projection;
}

function getPointToPolylineDistance(point: ShortPointDescription, polyline: ShortPointDescription[]): number {
  return Math.sqrt(squaredDist(point, pointProjectionToPolyline(point, polyline)));
}

function getPolylineLength(polyline: ShortPointDescription[]): number {
  let length = 0;
  for (let i = 0; i < polyline.length - 1; i++) {
    length += Math.sqrt(squaredDist(polyline[i], polyline[i + 1]));
  }
  return length;
}

export const Vector2Utils = {
  multiply,
  divide,
  subtract,
  add,
  squaredDist,
  dotProduct,
  pointProjectionToSegment,
  peprendicularVectorToLine,
  equal,
  isPointInPolygon,
  pointProjectionToPolyline,
  getPointToPolylineDistance,
  getPolylineLength,
};
